import { createRouter, createWebHashHistory } from "vue-router";

import Home from "@/views/Home";
import Login from "@/views/Login";
import SignUp from "@/views/SignUp";
import VerifyPage from "@/views/VerifyPage";
import ResetPassword from "@/views/ResetPassword";
import ChangePassword from "@/views/ChangePassword";
import VerifyEmail from "@/views/VerifyEmail";
import Profile from "@/views/Profile";
import Workspace from "@/views/Workspace";
import Tool from "@/views/Tool";
import Document from "@/views/Document";
import ProductPage from "@/views/ProductPage";
import TermsConditions from "@/views/TermsConditions";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import Pay from "@/views/Pay";
import PayTab from "@/views/PayTab";
import Orders from "@/views/Orders";
import About from "@/views/About";
import Contact from "@/views/Contact";
import PayResult from "@/views/PayResult";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/signUp",
    name: "signUp",
    component: SignUp,
  },
  {
    path: "/vPage",
    name: "vPage",
    component: VerifyPage,
  },
  {
    path: "/resetPasswd",
    name: "resetPasswd",
    component: ResetPassword,
  },
  {
    path: "/changePasswd",
    name: "changePasswd",
    component: ChangePassword,
  },
  {
    path: "/vEmail",
    name: "vEmail",
    component: VerifyEmail,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/workspace",
    name: "workspace",
    component: Workspace,
  },
  {
    path: "/tool",
    name: "tool",
    component: Tool,
  },
  {
    path: "/document",
    name: "document",
    component: Document,
  },
  {
    path: "/product",
    name: "product",
    component: ProductPage,
  },
  {
    path: "/terms",
    name: "terms",
    component: TermsConditions,
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/pay",
    name: "pay",
    component: Pay,
  },
  {
    path: "/paytab",
    name: "paytab",
    component: PayTab,
  },
  {
    path: "/order",
    name: "order",
    component: Orders,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/payResult",
    name: "payResult",
    component: PayResult,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.afterEach(() => {
  document.documentElement.scrollTop = document.body.scrollTop = 0;
});
export default router;
