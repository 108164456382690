<script>
import { useI18n } from "vue-i18n";
import axios from "axios";
import { computed, onMounted } from "vue";
import { useStripe } from "vue-use-stripe";

import config from "../config";
import { getHashSearch } from "../util";

import { useLoading } from "../components/Loading";
import products from "../store/product";
import { useRoute, useRouter } from "vue-router";
import Button from "../components/Button.vue";

export default {
  name: "PayTab",
  components: {
    Button,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    let { t } = useI18n();

    const $loading = useLoading({
      loader: "spinner",
      "background-color": "#000",
      opacity: 0.3,
      width: "28px",
      height: "28px",
      text: t("payPage.loading"),
      "lock-scroll": true,
    });

    const proId = route.query.proId;

    let search = getHashSearch() || window.location.search;

    let product = computed(() => {
      return products.filter((d) => {
        return d.id === proId;
      })[0];
    });

    const { stripe } = useStripe({ key: config.stripe.key });

    let order = null;

    function createOrder(force = false) {
      if (order && !force) {
        return Promise.resolve(order);
      }
      return axios
        .post(`${config.domains.pop}/api/accounts/me/orders`, {
          product: product.value.id,
        })
        .then((res) => {
          order = res.data;
          return Promise.resolve(order);
        })
        .catch((err) => {
          console.log(err);
          order = null;
          return Promise.resolve(null);
        });
    }

    async function grabpay() {
      const loader = $loading.show();
      const order = await createOrder(true);
      if (!order) {
        loader.hide();
        return;
      }

      const clientSecretRes = await axios.post(
        `${config.domains.pop}/api/accounts/me/orders/${order.id}/payments/stripe`,
        {
          pay_platform: "web",
          pay_method: "grabpay",
        }
      );

      if (clientSecretRes.status !== 200) {
        loader.hide();
        console.log("Pay failed.");
        await router.push({
          name: "payResult",
          params: { result: "error" },
          query: { proId: route.query.proId },
        });
        return;
      }
      stripe.value
        .confirmGrabPayPayment(
          clientSecretRes.data.url,
          {
            return_url:
              config.stripe.grabpay.returnUrl ||
              location.href.replace(location.hash, `#payResult${search}`),
          },
          {
            handleActions: false,
          }
        )
        .then((res) => {
          loader.hide();
          location.href = res.paymentIntent.next_action.redirect_to_url.url;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function alipay() {
      const loader = $loading.show();
      const order = await createOrder();
      if (!order) {
        loader.hide();
        return;
      }

      axios
        .post(
          `${config.domains.pop}/api/accounts/me/orders/${order.id}/payments/auto`,
          {
            pay_platform: "web",
            return_url:
              config.stripe.alipay.returnUrl ||
              location.href.replace(location.hash, `#payResult${search}`),
          }
        )
        .then((res) => {
          location.href = res.data.url;
          loader.hide();
        })
        .catch((err) => {
          loader.hide();
          console.log(`Pay order failed: ${err}.`);
          router.push({
            name: "payResult",
            params: { result: "error" },
            query: { proId: route.query.proId },
          });
        });
    }

    onMounted(() => {
      // TODO: callback page
      // const url = new URL(window.location.href);
      // const paymentIntentClientSecret = url.searchParams.get(
      //   'payment_intent_client_secret'
      // );
      // if (paymentIntentClientSecret) {
      //   stripe.value.retrievePaymentIntent(paymentIntentClientSecret).then(function(result) {
      //     const paymentIntent = result.paymentIntent;
      //     console.log(paymentIntent.status);
      //   });
      // }

      const type = route.query.paytype;
      if (!type) {
        router.push({ name: "product" });
        return;
      }

      if (type === "alipay") {
        alipay();
      } else if (type === "grabpay") {
        grabpay();
      }
    });

    return {
      alipay,
      grabpay,
    };
  },
  created() {},
};
</script>
