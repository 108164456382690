<template>
  <div class="pay-result">
    <div v-if="isSuccess" class="success flex">
      <div class="flex">
        <img src="@/assets/images/icon_success.svg" alt="load error" />
        <span
          class="title"
          v-html="$t(`payPage.success.${userMode}.title`)"
        ></span>
        <span class="desc" v-html="successDesc"></span>
      </div>
    </div>
    <div v-else class="error flex">
      <div
        class="mask"
        @click.self="
          router.push({ name: 'pay', query: { proId: route.query.proId } })
        "
      ></div>
      <div class="flex">
        <span
          class="title"
          v-html="$t(`payPage.error.${userMode}.title`)"
        ></span>
        <img src="@/assets/images/pay/sad.svg" alt="load error" />
        <span class="desc" v-html="$t(`payPage.error.${userMode}.desc`)"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { toBackNow } from "@/router/back";
import { i18nReplace } from "@/language/i18n";
import { useI18n } from "vue-i18n";
export default {
  name: "PayResult",
  components: {},
  setup() {
    let route = useRoute();
    let router = useRouter();
    let invTime = ref(3);
    let isSuccess = ref(true);

    if (route.params.result && route.params.result === "error") {
      isSuccess.value = false;
    }

    let userMode = ref(window.document.body.clientWidth < 765 ? "mb" : "pc");
    let { t } = useI18n();
    let successDesc = computed(() => {
      let rep = [{ time: invTime.value.toString() }];
      return i18nReplace(t(`payPage.success.${userMode.value}.desc`), rep);
    });

    let interval = null;
    onMounted(() => {
      if (isSuccess.value) {
        interval = window.setInterval(function () {
          if (invTime.value > 1) {
            invTime.value--;
          } else {
            invTime.value = 1;
            window.clearInterval(interval);
            toBackNow("buy");
          }
        }, 1000);
      }
    });

    return {
      userMode,
      successDesc,
      isSuccess,
      route,
      router,
    };
  },
  created() {},
};
</script>

<style scoped>
.flex {
  flex-direction: column;
}
.title {
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
}
.desc {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
}
.pay-result {
  height: 100%;
}
.success {
  height: 100%;
  justify-content: center;
}
.success .title {
  margin: 16px 0 8px 0;
}
.success img {
  width: 40px;
  height: 40px;
}
.error {
  height: 100%;
}
.error .flex {
  padding: 26px;
  z-index: 13;
  background: #ffffff;
  border-radius: 8px;
}
.error img {
  margin: 22px 0 14px 0;
}

@media all and (max-width: 475px) and (min-width: 320px) {
  .success .title {
    margin: 24px 0 14px 0;
  }
}
</style>
