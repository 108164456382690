<template>
  <div class="tool">
    <div class="title flex" :class="store.isLogin ? 'left' : 'center'">
      <span v-if="store.isLogin">{{ $t("tools.loginTitle") }}</span>
      <span v-else>{{ $t("tools.notLoginTitle") }}</span>
    </div>
    <DetailsToolBlock
      class="tool-block"
      :class="store.isLogin ? 'left' : 'center'"
    ></DetailsToolBlock>

    <div class="short-tool-list">
      <div class="title">{{ $t("convertPage.convertToPDFToolName") }}</div>
      <div
        class="item"
        :key="'tool-page' + key"
        v-for="(item, key) in fromPDFTools"
      >
        <DetailsToolBox
          :desc-color="item.descColor"
          :desc="$t(`tools.toolDescText.${key}`)"
          :style="{ background: item.background, color: item.textColor }"
          :icon="item.iconTool"
          :name="
            item.type === 'convert'
              ? `${store.files[item.from].name} ${$t('convertPage.to')} ${
                  store.files[item.to].name
                }`
              : item.type === 'split'
              ? `${$t('splitPage.name')} ${store.files[item.to].name}`
              : `${$t('mergePage.name')} ${store.files[item.to].name}`
          "
          :work="key"
        ></DetailsToolBox>
      </div>
      <div class="title">{{ $t("convertPage.convertFromPDFToolName") }}</div>
      <div
        class="item"
        :key="'tool-page' + key"
        v-for="(item, key) in toPDFTools"
      >
        <DetailsToolBox
          :desc-color="item.descColor"
          :desc="$t(`tools.toolDescText.${key}`)"
          :style="{ background: item.background, color: item.textColor }"
          :icon="item.iconTool"
          :name="
            item.type === 'convert'
              ? `${store.files[item.from].name} ${$t('convertPage.to')} ${
                  store.files[item.to].name
                }`
              : item.type === 'split'
              ? `${$t('splitPage.name')} ${store.files[item.to].name}`
              : `${$t('mergePage.name')} ${store.files[item.to].name}`
          "
          :work="key"
        ></DetailsToolBox>
      </div>
      <div class="title">{{ $t("convertPage.mergeAndSplitToolName") }}</div>
      <div
        class="item"
        :key="'tool-page' + key"
        v-for="(item, key) in otherTools"
      >
        <DetailsToolBox
          :desc-color="item.descColor"
          :desc="$t(`tools.toolDescText.${key}`)"
          :style="{ background: item.background, color: item.textColor }"
          :icon="item.iconTool"
          :name="
            item.type === 'convert'
              ? `${store.files[item.from].name} ${$t('convertPage.to')} ${
                  store.files[item.to].name
                }`
              : item.type === 'split'
              ? `${$t('splitPage.name')} ${store.files[item.to].name}`
              : `${$t('mergePage.name')} ${store.files[item.to].name}`
          "
          :work="key"
        ></DetailsToolBox>
      </div>
    </div>
  </div>
  <ColumnToolsAndSiteMap :showTool="false"></ColumnToolsAndSiteMap>
</template>

<script>
import { useStore } from "vuex";
import DetailsToolBlock from "../components/DetailsToolBlock";
import DetailsToolBox from "../components/DetailsToolBox";
import ColumnToolsAndSiteMap from "../components/ColumnToolsAndSiteMap";
import { otherTools, toPDFTools, fromPDFTools } from "@/store/typeTools";
export default {
  name: "Tool",
  components: { DetailsToolBox, DetailsToolBlock, ColumnToolsAndSiteMap },
  setup() {
    let store = useStore().state;

    return {
      store,
      otherTools,
      toPDFTools,
      fromPDFTools,
    };
  },
};
</script>

<style scoped>
.tool {
  width: 100%;
  padding-top: 40px;
}
.short-tool-list {
  display: none;
  flex-direction: column;
  padding: 0 24px;
}
.short-tool-list .title {
  text-align: start;
  padding: 24px 0;
  row-gap: 14px;
  margin: 0;
  font-size: 18px;
}
.short-tool-list .title:not(:first-child) {
  border-top: 1px solid #e9e9e9;
  margin: 26px 0 0 0;
}
.short-tool-list .item {
  margin-bottom: 14px;
}
.title {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 28px;
  width: 100%;
  text-align: center;
}
.center {
  justify-content: center;
}
.left {
  justify-content: flex-start;
}

@media all and (max-width: 765px) and (min-width: 320px) {
  .tool-block {
    display: none;
  }
  .short-tool-list {
    display: flex;
    margin-bottom: 46px;
  }
  .tool {
    padding-top: 24px;
  }

  .tool > .title {
    padding: 0 24px;
    box-sizing: border-box;
    margin-bottom: 0px;
    justify-content: center;
  }
}

@media all and (max-width: 1020px) and (min-width: 765px) {
}
@media all and (min-width: 1020px) {
}
</style>
