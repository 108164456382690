<template>
  <div class="orders">
    <div class="title">
      {{ $t("orderPage.title") }}
    </div>

    <div v-if="loading" class="loading flex">
      <Load></Load>
      {{$t("payPage.loading")}}
    </div>
    <div v-if="!loading" class="content-row">
      <table class="show-list">
        <tbody>
          <tr>
            <th>{{ $t("orderPage.table.date") }}</th>
            <th>{{ $t("orderPage.table.plan") }}</th>
            <th class="text-end">{{ $t("orderPage.table.paid") }}</th>
            <th>{{ $t("orderPage.table.invoice") }}</th>
          </tr>
          <tr v-for="(order, index) in orders" :key="`order-${index}`">
            <td>
              <span>{{ timeFormat(order.pay_at) }}</span>
            </td>
            <td>{{ $t(`productPage.${order.product.id}.plan`) }}</td>
            <td class="text-end">
              SG${{ utils.calculateDecimal(order.fee / 100) }}
            </td>
            <td class="action">
              <!--              <img src="@/assets/images/account/icon_download.png" alt="load error" />-->
              {{ $t("action.pending") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="!loading" class="content-col">
      <div class="flex body" v-for="order in orders">
        <div class="flex text-info">
          <div><span class="name">{{ $t("orderPage.table.date") }}</span><span>:&nbsp;{{ timeFormat(order.pay_at) }}</span></div>
          <div><span class="name">{{ $t("orderPage.table.plan") }}</span><span>:&nbsp;{{ $t(`productPage.${order.product.id}.plan`) }}</span></div>
          <div><span class="name">{{ $t("orderPage.table.paid") }}</span><span>:&nbsp;SG${{ utils.calculateDecimal(order.fee / 100) }}</span></div>
        </div>
        <Button v-if="false">
          {{ $t("action.download") }}
        </Button>
        <button v-else class="unactive">
          {{ $t("action.pending") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, inject, ref } from "vue";
import utils from "@/util/utils";
import Button from "@/components/Button";
import Load from "@/components/Loading/loaders/spinner";

export default {
  name: "Orders",
  components: { Button, Load },
  setup() {
    let orders = reactive([]);
    let timeFormat = inject("timeFormat");

    let loading = ref(false);

    function load() {
      loading.value = true;
      utils
        .getOrders()
        .then((res) => {
          if (res.data) {
            res.data.forEach((d) => {
              orders.push(d);
            });
          }
        })
        .catch((e) => {
          console.log(e);
        }).finally(() => {
        loading.value = false;
      });
    }
    return {
      loading,
      load,
      timeFormat,
      orders,
      utils,
    };
  },
  created() {
    this.load();
  },
};
</script>

<style scoped>
.orders {
  padding-top: 40px;
  width: 100%;
}
.title {
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 28px;
}

.action, .unactive {
  cursor: not-allowed !important;

}
.unactive {
  background: #DBDBDB;
  border-radius: 4px;
}

.content-col {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px 28px 24px;
}
.name {
  font-weight: 700;
}

.content-col .body {
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 28px;
}

.content-col .body:not(:first-child) {
  padding-top: 28px;
}
.content-col .body:not(:last-child) {
  border-bottom: 1px solid #e9e9e9;
}

.content-col .text-info {
  flex-direction: column;
  align-items: flex-start;
}
.content-col .text-info > div:not(:first-child) {
  margin-top: 8px;
}
.content-col button {
  min-width: 95px;
  width: 95px;
  height: 38px;
  margin-left: 20px;
}

.loading {
  margin-top: 168px;
  flex-direction: column;
}
.loading svg{
  width: 28px;
  height: 28px;
  margin-bottom: 10px;
}

@media all and (max-width: 570px) and (min-width: 320px) {
  .content-row {
    display: none;
  }
  .content-col {
    display: flex;
  }

  .title {
    text-align: center;
  }

}
@media all and (max-width: 1020px) and (min-width: 570px) {
  .title {
    width: 80%;
    margin: auto;
  }
}

</style>
