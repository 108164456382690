<template>
  <div class="banner flex">
    <div class="flex slogan">
      <h1 class="mainSlogan" v-html="$t('home.banner.mainSlogan')"></h1>
      <div class="subSlogan" v-html="$t(`home.banner.subSlogan.${userMode}`)"></div>
      <ToolsButton class="tool-button-top flex">
        {{ $t("home.banner.buttonText") }}
      </ToolsButton>
    </div>
    <div class="banner-img-parent img-pc">
      <img class="banner-img" src="@/assets/images/home/banner.png" alt="load error" />
    </div>
    <div class="banner-img-parent img-mb">
      <img
        class="banner-img"
        src="@/assets/images/home/banner_mobile.svg"
        alt="load error"
      />
    </div>
    <ToolsButton class="tool-button-bottom flex">
      {{ $t("home.banner.buttonText") }}
    </ToolsButton>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import ToolsButton from "@/components/ToolsButton";
import { ref } from "vue";

export default {
  name: "Banner",
  components: { ToolsButton },
  setup() {
    let router = useRouter();
    let userMode = ref(window.document.body.clientWidth < 1020 ? "mb" : "pc");

    return { router, userMode };
  },
  created() {},
};
</script>

<style scoped>
.banner {
  background: #e6f2ff;
  border-radius: 2px;
  justify-content: space-between;
  margin-bottom: 60px;
  padding: 40px 14px 28px 14px;
  flex-direction: column;
}
.banner > div {
  display: flex;
}
.subSlogan {
  font-weight: 400;
  margin-bottom: 40px;
  margin-top: 28px;
  font-size: 22px;
  line-height: 28px;
}
.slogan {
  text-align: start;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
}
.slogan a {
  cursor: pointer;
  font-size: 16px;
  background: #00a3ff;
  border-radius: 4px;
  text-align: center;
}
.mainSlogan {
  font-weight: 700;
  font-size: 38px;
}
.banner button {
  font-size: 18px;
}
.banner .img-mb {
  display: none;
}
.banner .banner-img-parent {
  align-items: center;
  justify-content: center;
}
.banner .banner-img {
  width: 60%;
  height: auto;
  border-radius: 2px;
  min-width: 347px;
  min-height: 291px;
}
.tool-button-bottom {
  margin-top: 24px;
  display: inherit;
}
.tool-button-top {
  display: none;
}


@media all and (max-width: 374px) and (min-width: 320px) {
  .mainSlogan {
    font-size: 30px;
  }
  .subSlogan {
    font-size: 18px;
  }
  .banner {
    padding-left: 6px;
    padding-right: 6px;
  }
  .banner .banner-img {
    font-size: 18px;
    min-width: 320px;
    height: auto;
  }
}
@media all and (max-width: 375px) and (min-width: 320px) {
  .mainSlogan {
    font-size: 34px;
  }
  .subSlogan {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 24px;
    margin-top: 12px;
  }
}

@media all and (max-width: 765px) and (min-width: 320px) {
  .banner .img-mb {
    display: block;
  }
  .banner .img-pc {
    display: none;
  }
}

@media all and (max-width: 500px) and (min-width: 375px) {
  .mainSlogan {
    font-size: 36px;
  }
  .subSlogan {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 24px;
    margin-top: 12px;
    max-width: 350px;
  }
}
@media all and (max-width: 1020px) and (min-width: 500px) {
  .mainSlogan {
    font-size: 40px;
  }
  .subSlogan {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 8px;
    margin-top: 8px;
    max-width: 370px;
  }
}
@media all and (min-width: 1020px) {
  .tool-button-bottom {
    display: none;
  }
  .tool-button-top {
    display: inherit;
  }
  .slogan {
    margin-left: 70px;
  }
  .mainSlogan {
    white-space: nowrap;
  }
  .subSlogan {
    margin-top: 8px;
    margin-bottom: 32px;
    font-size: 18px;
  }
  .banner {
    flex-direction: row;
    margin-bottom: 50px;
    padding: 0;
  }
  .banner .banner-img-parent {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .banner .banner-img {
    font-size: 18px;
    width: 471px;
    height: auto;
  }
}
</style>
