<template>
  <div>Doc</div>
</template>

<script>
// import { toRefs, reactive, ref } from 'vue'

export default {
  name: "Document",
  components: {},
  setup() {},
  created() {},
};
</script>

<style scoped></style>
