import { reactive } from "vue";
let loadProducts = [
  {
    id: "pro_0",
    name: "Free",
    backgroundColor: "#FAFAFA",
    textColor: "#454545",
    divideLineColor: "#E7E7E7",
    billedColor: "#E7E7E7",
    price: "0",
    totalPrice: "0",
    days: "0",
    period: "",
    billed: "",
  },
  {
    id: "pro_30d",
    name: "PRO",
    backgroundColor: "#EFFCEF",
    textColor: "#448042",
    divideLineColor: "#C5EEC3",
    billedColor: "#92D690",
    price: {
      en: "6.30",
      zh: "39.8",
      zh_tc: "39.8",
    },
    totalPrice: {
      en: "6.30",
      zh: "39.80",
      zh_tc: "39.80",
    },
    days: "30",
    period: "month",
    monthConversion: 1,
  },
  {
    id: "pro_90d",
    name: "PRO",
    backgroundColor: "#EEF8FF",
    textColor: "#4E6796",
    divideLineColor: "#CDECFF",
    billedColor: "#99C9E5",
    price: {
      en: "5.57",
      zh: "36",
      zh_tc: "36",
    },
    totalPrice: {
      en: "17.00",
      zh: "108.00",
      zh_tc: "108.00",
    },
    days: "90",
    period: "quarterly",
    monthConversion: 3,
  },
  {
    id: "pro_180d",
    name: "PRO",
    backgroundColor: "#EEF8FF",
    textColor: "#4E6796",
    divideLineColor: "#CDECFF",
    billedColor: "#99C9E5",
    price: {
      en: "5.33",
      zh: "33",
      zh_tc: "33",
    },
    totalPrice: {
      en: "32.00",
      zh: "198.00",
      zh_tc: "198.00",
    },
    days: "180",
    period: "halfYearly",
    monthConversion: 6,
  },
  {
    id: "pro_365d",
    name: "PRO",
    backgroundColor: "#FFF7EE",
    textColor: "#95500B",
    divideLineColor: "#FDE0C7",
    billedColor: "#E4C4A4",
    price: {
      en: "4.75",
      zh: "29.8",
      zh_tc: "29.8",
    },
    totalPrice: {
      en: "57.00",
      zh: "358.00",
      zh_tc: "358.00",
    },
    days: "365",
    period: "yearly",
    monthConversion: 12,
  },
];

try {
  loadProducts = require(`./${process.env.VUE_APP_APP}/product`).default;
} catch (_) {
  console.log("err.");
}
let products = reactive(loadProducts);

export default products;
