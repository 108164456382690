<template>
  <div class="card-form" :class="{ ready: ready }">
    <StripeElement :element="paymentElement" @ready="readyHanlder" />
    <button class="submit" @click="submit">Pay</button>
  </div>
</template>

<script>
import { useStripe, StripeElement } from "vue-use-stripe";

import config from "../config";

export default {
  name: "CardForm",
  components: { StripeElement },
  props: {
    clientSecret: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const {
      stripe,
      elements: [paymentElement],
      stripeElements,
    } = useStripe({
      key: config.stripe.key,
      constructorOptions: {
        locale: "en",
      },
      elements: [
        {
          type: "payment",
          options: {},
        },
      ],
      elementsOptions: {
        clientSecret: props.clientSecret,
      },
    });

    function submit() {
      if (!stripe.value) {
        return;
      }
      context.emit("submit", {
        stripe: stripe.value,
        elements: stripeElements.value,
      });
    }

    function readyHanlder() {
      context.emit("ready");
    }

    return {
      paymentElement,
      readyHanlder,
      submit,
    };
  },
};
</script>

<style lang="less" scoped>
.card-form {
  background: #fff;
}

.submit {
  margin-top: 28px;
  width: 100%;
}
</style>
