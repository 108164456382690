let products = [
  {
    id: "pro_0",
    name: "Free",
    backgroundColor: "#FAFAFA",
    textColor: "#454545",
    divideLineColor: "#E7E7E7",
    billedColor: "#E7E7E7",
    price: "0",
    totalPrice: "0",
    days: "0",
    period: "",
    billed: "",
  },
  {
    id: "pro_30d",
    name: "PRO",
    backgroundColor: "#EFFCEF",
    textColor: "#448042",
    divideLineColor: "#C5EEC3",
    billedColor: "#92D690",
    price: {
      en: "8.5",
      zh: "8.5",
      zh_tc: "8.5",
    },
    totalPrice: {
      en: "8.50",
      zh: "8.50",
      zh_tc: "8.50",
    },
    days: "30",
    period: "month",
    monthConversion: 1,
  },
  {
    id: "pro_90d",
    name: "PRO",
    backgroundColor: "#EEF8FF",
    textColor: "#4E6796",
    divideLineColor: "#CDECFF",
    billedColor: "#99C9E5",
    price: {
      en: "7.67",
      zh: "7.67",
      zh_tc: "7.67",
    },
    totalPrice: {
      en: "23",
      zh: "23",
      zh_tc: "23",
    },
    days: "90",
    period: "quarterly",
    monthConversion: 3,
  },
  {
    id: "pro_180d",
    name: "PRO",
    backgroundColor: "#EEF8FF",
    textColor: "#4E6796",
    divideLineColor: "#CDECFF",
    billedColor: "#99C9E5",
    price: {
      en: "7",
      zh: "7",
      zh_tc: "7",
    },
    totalPrice: {
      en: "42",
      zh: "42",
      zh_tc: "42",
    },
    days: "180",
    period: "halfYearly",
    monthConversion: 6,
  },
  {
    id: "pro_365d",
    name: "PRO",
    backgroundColor: "#FFF7EE",
    textColor: "#95500B",
    divideLineColor: "#FDE0C7",
    billedColor: "#E4C4A4",
    price: {
      en: "6.33",
      zh: "6.33",
      zh_tc: "6.33",
    },
    totalPrice: {
      en: "76",
      zh: "76",
      zh_tc: "76",
    },
    days: "365",
    period: "yearly",
    monthConversion: 12,
  },
];

export default products;
