<template>
  <Access v-if="false"></Access>
  <CookieUse v-if="!canUseCookie" @authCookie="authCookie"></CookieUse>
  <HeadUp ref="head" :theme-color="store.themeColor"></HeadUp>
  <div class="main">
    <router-view />
  </div>
  <Footer :theme-color="store.themeColor"></Footer>
</template>

<script>
import { useStore } from "vuex";

import HeadUp from "./components/Head.vue";
import Footer from "./components/Footer.vue";
import Access from "./components/Access.vue";
import CookieUse from "./components/CookieUse";
import utils from "./util/utils";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { computed, provide, ref, onMounted } from "vue";
import Button from "@/components/Button";
import "@/assets/css/varlet.css"

try {
  require(`@/assets/css/${process.env.VUE_APP_APP}/global.css`);
} catch {
  require(`@/assets/css/global.css`);
}

export default {
  name: "App",
  components: {
    Button,
    Access,
    HeadUp,
    Footer,
    CookieUse,
  },
  // props; context: attrs、slots、emit
  // setup()内使用响应式ref数据时，需要通过.value获取
  setup() {
    let useStores = useStore();
    let store = useStores.state;

    let canUseCookie = ref(!!utils.getCookie('ccu'));
    function authCookie() {
      canUseCookie.value = true;
    }

    async function load() {
      await useStores.dispatch("account/init");
      let login = !!utils.getCookie("sid") && !!store.account.email;
      if (login) {
        // utils.getMe().then((res) => {
        //   Account.storage(JSON.stringify(res.data));
        //   useStores.dispatch("account/init");
        // });
        let res = getRecents();
        useStores.commit("change", {
          key: "recents",
          value: res,
        });
      }
      useStores.commit("changeLogin", login);
    }
    load();

    function getRecents() {
      const res = utils.getRecents(store.account.code);
      let result = [];
      try {
        result = res.length <= 3 ? res : res.slice(0, 3 - res.length);
      } catch (e) {
        console.log(e);
      }
      return result;
    }

    function timeFormat(date) {
      const { locale } = useI18n();
      const language = locale.value;
      let formatStr = "MMMM dd[,] YYYY";
      if (language === "en") {
        formatStr = "MMMM DD[,] YYYY";
      } else if (language === "zh") {
        formatStr = "YYYY-MM-DD";
      } else if (language === "zh_tc") {
        formatStr = "YYYY-MM-DD";
      }
      return moment(date).format(formatStr);
    }

    provide("timeFormat", timeFormat);

    let meExpire = computed(() => {
      return timeFormat(store.account.vip.expire);
    });

    provide("meExpire", meExpire);

    let payType = computed(() => {
      const expire = new Date(store.account.vip.expire);
      const level = Number(store.account.vip.level);
      const date = new Date();
      if (date <= expire && level > 0) return "paid";
      if (date > expire && level > 0) return "paidAndExpired";
      if (date > expire && level <= 0) return "free";
      return "free";
    });

    provide("payType", payType);

    let head = ref(null);
    function headTopup() {
      head.value.showAccount();
    }
    let isHeadTopup = computed(() => head.value.isPopup)
    function headTopupHide() {
      head.value.hidePopUp();
    }
    function mobileHeadTopup() {
      if (window.document.body.clientWidth < 765) {
        headTopup();
        return true;
      }
      return false;
    }
    provide("headTopup", headTopup);
    provide("isHeadTopup", isHeadTopup);
    provide("headTopupHide", headTopupHide);
    provide("mobileHeadTopup", mobileHeadTopup);

    onMounted(() => {
      document.body.addEventListener("touchstart", ()=>{});
    })


    return {
      head,
      load,
      store,
      canUseCookie,
      authCookie,
    };
  },
  // },
  created() {
    // this.load();
  },
};
</script>
<style type="text/css">
* {
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
}
html {
  padding: 0;
  margin: 0;
  height: 100%;
  /* line-height: 1.15; */
  -webkit-text-size-adjust: 100%;
}
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  color: #454545;
}
h1,h2,h3,h4,h5 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #454545;
}


.cookie-use .desc img{
  vertical-align: middle;
  margin-bottom: 1px;
}

a:active {
  color: #0063c7;
}

.main {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
}
#app {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
hr {
  margin: 0;
  border: none;
  border-bottom: 1px solid #d9d9d9;
}
.cookie-use a,
.tip a {
  color: #00a3ff;
}

@media (any-hover: hover){
  button:hover {
    background-color: #0e85fc;
  }

  a:hover {
    color: #00a3ff;
  }
}

button:active {
  background-color: #0063c7;
}

button {
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  cursor: pointer;
  height: 36px;
  border: none;
  background: #00a3ff;
  border-radius: 4px;
  min-width: 96px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  padding: 0 18px;
  outline:none;
}
button:focus {
  outline:none;
}
button::-moz-focus-inner {
  border-color: transparent;
}

.mask {
  z-index: 8;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
input {
  -webkit-user-select: text !important;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .main {
    width: 100%;
  }
  button {
    font-size: 16px;
    height: 44px;
  }
}

@media all and (max-width: 765px) and (min-width: 640px) {
  .main {
    width: 100%;
  }
}

@media all and (max-width: 1020px) and (min-width: 765px) {
  .main {
    width: 100%;
  }
}
@media all and (min-width: 1020px) {
  .main {
    width: 920px;
  }
}
</style>
