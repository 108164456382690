<template>
  <div class="home">
    <Banner></Banner>

    <h1 class="flex tool-title">{{ $t("home.toolSlogan") }}</h1>

    <OneWayToolBlock class="tool-block"></OneWayToolBlock>

    <div class="to-tool flex">
      <ToolsButton>
        {{ $t("home.toolHint") }}
      </ToolsButton>
    </div>

    <HomeShowBlock></HomeShowBlock>

    <HomeShowPlan></HomeShowPlan>

    <ChooseUs class="choose"></ChooseUs>

    <ColumnToolsAndSiteMap :showTool="false"></ColumnToolsAndSiteMap>
  </div>
</template>

<script>
import OneWayToolBlock from "@/components/OneWayToolBlock";
import ColumnToolsAndSiteMap from "@/components/ColumnToolsAndSiteMap";
import ChooseUs from "@/components/ChooseUs";
import Banner from "@/components/Banner";
import ToolsButton from "@/components/ToolsButton";
import HomeShowBlock from "@/components/HomeShowBlock";
import HomeShowPlan from "@/components/HomeShowPlan";

export default {
  name: "Home",
  components: {
    OneWayToolBlock,
    ColumnToolsAndSiteMap,
    ChooseUs,
    Banner,
    ToolsButton,
    HomeShowBlock,
    HomeShowPlan,
  },
  setup() {},
  created() {},
};
</script>

<style scoped>
.home {
  text-align: center;
}

.tool-title,
.title {
  font-weight: 700;
  font-size: 24px;
}
a {
  height: 36px;
  box-sizing: border-box;
  text-decoration: none;
  color: #ffffff;
  background: #00a3ff;
  border-radius: 4px;
  padding: 8px 20px;
  font-weight: 700;
}
.tool-block {
  margin: 32px 0;
}
.to-tool {
  height: 36px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

.choose {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media all and (max-width: 765px) and (min-width: 320px) {
  .home {
    width: 100%;
  }
  .tool-title {
    font-size: 32px;
  }
}

@media all and (max-width: 1020px) and (min-width: 765px) {
  .home {
    width: 100%;
  }
}

@media all and (min-width: 1020px) {
  .home {
    width: 920px;
  }
}
</style>
