<template>
  <div class="show-block flex">
    <h1 class="title" v-html="$t('home.showBlock.title')"></h1>
    <div class="flex block1">
      <div class="flex show-text">
        <span
          class="show-title"
          v-html="$t(`home.showBlock.block1.${userMode || 'pc'}.mainTitle`)"
        ></span>
        <div
          class="show-desc"
          v-html="$t(`home.showBlock.block1.${userMode || 'pc'}.details`)"
        ></div>
      </div>
      <div class="show-img">
        <img src="@/assets/images/home/el1.png" alt="load error" />
      </div>
    </div>

    <div class="flex block2">
      <div class="flex show-text">
        <span
          class="show-title"
          v-html="$t(`home.showBlock.block2.${userMode || 'pc'}.mainTitle`)"
        ></span>
        <div
          class="show-desc"
          v-html="$t(`home.showBlock.block2.${userMode || 'pc'}.details`)"
        ></div>
      </div>
      <div class="show-img">
        <img src="@/assets/images/home/el2.png" alt="load error" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "HomeShowBlock",
  components: {},
  setup() {
    let userMode = ref(window.document.body.clientWidth < 765 ? "mb" : "pc");
    return {
      userMode,
    };
  },
  created() {},
};
</script>

<style scoped>
.show-block {
  flex-direction: column;
  margin: 60px 0;
  text-align: center;
}
.show-block .title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}
.show-block > div, .show-block .title {
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
}
.show-block .show-img {
  width: auto;
  height: 50%;
}
.show-block img {
  width: 100%;
  height: auto;
  min-width: 327px;
  max-width: 400px;
}
.show-title {
  margin-top: 28px;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
}
.show-desc {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
}
.show-text {
  text-align: center;
  flex-direction: column;
  align-items: center;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .show-block > div {
    flex-direction: column;
  }
  .show-block .title {
    width: 90%;
  }
  .block2 {
    margin-top: 50px;
  }
  .show-block > div .show-img {
    order: -1;
  }
}
@media all and (max-width: 765px) and (min-width: 640px) {
  .show-block > div {
    flex-direction: column;
  }
  .show-block > div .show-img {
    order: -1;
  }
}
@media all and (max-width: 374px) and (min-width: 320px) {
  .show-block img {
    min-width: 100%;
  }
}

@media all and (min-width: 765px) {
  .show-block {
    margin: 50px 0;
  }
  .show-block > div {
    padding: 0;
  }
  .show-text {
    align-items: flex-start;
  }
  .show-block .title {
    font-size: 24px;
    margin-bottom: 0;
  }

  .show-title {
    text-align: start;
    font-size: 18px;
    margin-bottom: 14px;
    margin-top: 0;
  }

  .show-desc {
    text-align: start;
    font-size: 14px;
  }
  .show-block img {
    width: 388px;
    height: 292px;
  }

  .show-block > div:nth-of-type(2n - 1) .show-img {
    order: -1;
  }
  .show-block > div:nth-of-type(2n - 1) .show-text {
    margin-left: 32px;
    order: 1;
  }
  .show-block > div:nth-of-type(1n) .show-text {
    margin-right: 10px;
  }
  .show-block > div:nth-of-type(1n) {
    justify-content: flex-end;
  }
  .show-block > div:nth-of-type(2n - 1) {
    margin-top: 14px;
    justify-content: flex-start;
  }
  .block1 .show-desc {
    max-width: 401px;
  }
  .block2 .show-desc {
    max-width: 429px;
  }
}
@media all and (max-width: 1020px) and (min-width: 765px) {
  .show-block > div {
    padding: 0 24px;
  }
}
</style>
