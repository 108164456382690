<template>
  <div class="tool-block">
    <div class="item" :key="key" v-for="(item, key) in store.tools">
      <DetailsToolBox
        :desc-color="item.descColor"
        :desc="$t(`tools.toolDescText.${key}`)"
        :style="{ background: item.background, color: item.textColor }"
        :icon="item.iconTool"
        :name="
          item.type === 'convert'
            ? `${store.files[item.from].name} ${$t('convertPage.to')} ${
                store.files[item.to].name
              }`
            : item.type === 'split'
            ? `${$t('splitPage.name')} ${store.files[item.to].name}`
            : `${$t('mergePage.name')} ${store.files[item.to].name}`
        "
        :work="key"
      ></DetailsToolBox>
    </div>
  </div>
</template>

<script>
// import { toRefs, reactive, ref } from 'vue'
import { useStore } from "vuex";
import DetailsToolBox from "./DetailsToolBox";
export default {
  name: "DetailsToolBlock",
  components: { DetailsToolBox },
  props: {},
  setup() {
    let useStores = useStore();
    let store = useStores.state;
    return {
      store,
    };
  },
  created() {},
};
</script>

<style scoped>
.tool-block {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
}

.item:hover {
  /*background-color: #3d99f5;*/
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .tool-block {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-row-gap: 24px;
    padding: 0 24px;
  }
}

@media all and (max-width: 765px) and (min-width: 640px) {
  .tool-block {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    grid-row-gap: 14px;
    padding: 0 24px;
  }
}

@media all and (max-width: 1020px) and (min-width: 765px) {
  .tool-block {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 24px;
    padding: 0 24px;
  }
}
@media all and (min-width: 1020px) {
}
</style>
