<template>
  <div class="main-content">
    <div class="route-space">
      <img src="@/assets/images/privacy.png" alt="terms" />
      <a
        class="nav nav-left"
        href="#/privacyPolicy"
        :class="{ checked: route.path === '/privacyPolicy' }"
        >{{ $t("privacyPolicy.title") }}</a
      >
      <a
        class="nav nav-right"
        href="#/terms"
        :class="{ checked: route.path === '/terms' }">{{
        $t("termsConditions.title")
      }}</a>
    </div>

    <div class="page">
      <h1 class="page-title">Terms &amp; Conditions</h1>
      <p>
        Welcome to CyberPDF (from this point onwards &quot;the Service.&quot;) The
        Service offers its users solely a web and mobile application which
        allows users to manipulate documents and/or images through online
        software.
      </p>
      <p>
        These Terms and Conditions ("Agreement") are a legal agreement between you, or an entity you are acting on behalf of, and CYBERHEART PTE. LTD. (hereinafter "CyberPDF"). These terms govern your use of CyberPDF Online and other products or services link to this Agreement.
      </p>
      <h2>1. Use of Our Service</h2>
      <h3>1.1 General</h3>
      <p>
        This page explains the terms by which you may use our online and/or
        mobile services, website, and software provided on or in connection with
        our services. By accessing or using CyberPDF you agree to be conformant to
        this Terms and Conditions agreement (&quot;Agreement&quot;) whether or
        not you are registered on our services. In the case of disagreement with
        all or part of these Terms and Conditions, you should abstain from using
        the Service.
      </p>
      <p class="not-bottom">
        By means of acceptance of the current Terms and Conditions, the User
        agrees to comply with the following service rules:
      </p>
      <ul>
        <li>To have read and understood what is explained here.</li>
        <li>To have assumed all of the obligations that are stated here.</li>
        <li>
          To use the service solely for purposes permitted by law and which do
          not violate the rights of a third-party.
        </li>
        <li>
          To not use this website for any unlawful activity. You are prohibited
          to break any term and condition to not generate content dedicated to
          creating SPAM or which could provide instructions about how to engage
          in illegal activities.
        </li>
        <li>
          To not gather, handle, or store personal information about other Users
          or third-parties without complying with the current legislation
          regarding the protection of information.
        </li>
      </ul>
      <p>
        If the regulations in the Terms and Conditions are in contradiction with
        the privacy policy, Terms and Conditions will prevail.
      </p>
      <p>
        Failure to comply with these obligations may result in the cancellation
        of the Contract, as is established in Clause 8.
      </p>
      <h3>1.2 Service Rules</h3>
      <p>
        Your use of the Service is subject to this Reasonable Use Policy, which
        has been created to ensure that our service is fair for both users and
        developers.
      </p>
      <p class="not-bottom">
        The following is not permitted in connection with CyberPDF Services:
      </p>

      <ul>
        <li>
          Using any automated or non-automated scraping process (including bots,
          scrapers, and spiders) in conjunction with CyberPDF;
        </li>
        <li>
          Converting or otherwise editing documents with CyberPDF at a rate that
          exceeds what a human can reasonably do by using manual means and a
          conventional device;
        </li>
        <li>
          Providing your password to any other person or using any other persons
          username and password to access CyberPDF;
        </li>
        <li>
          Abusing CyberPDF in excess of what is reasonably needed or required for
          legitimate business or personal purposes. CyberPDF may investigate any
          account that registers over 1000 tasks in a month to determine
          compliance with this requirement.
        </li>
      </ul>

      <p>
        If CyberPDF determines that you are in breach of this policy, we may
        temporarily or permanently suspend or terminate your account or your
        subscription to the Service.
      </p>
      <h2>2. Accounts</h2>
      <h3>2.1 General</h3>
      <p class="not-bottom">
        CyberPDF accounts give the user access to the services and functionality
        that we may establish and maintain from time to time and in our sole
        discretion. We may maintain different types of accounts for different
        types of Users. The different account types allow the user to work
        within different file size and file number limitations. Our Service
        users&#x27; types are as follows:
      </p>
      <ul>
        <li>Not registered</li>
        <li>Registered</li>
        <li>PRO</li>
      </ul>
      User is solely responsible for the activity that occurs on his account and
      must keep his account password secure.

      <p>
        CyberPDF owns the right to totally or partially stop providing any of its
        Services whenever it considers it appropriate and would only give prior
        notification to PRO Users. In the previous operations, the corresponding
        taxes will be applied to the Users, assuming payment whomever, in
        conformity with the current regulation, is considered a passive subject
        of these operations.
      </p>
      <h2>3. User Content</h2>
      <h3>3.1 General</h3>
      <p class="not-bottom">
        CyberPDF does not analyse the content of files whilst processing them and
        only Users will have access to the edited files once CyberPDF has
        processed them. If chosen by the user, this link can be shared with
        someone else. CyberPDF will automatically delete processed files from
        their servers after a defined period of time depending on the tool used:
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              <li>Word to PDF</li>
            </td>
            <td>1 hours</td>
          </tr>
          <tr>
            <td><li>Excel to PDF</li></td>
            <td>1 hours</td>
          </tr>
          <tr>
            <td><li>PowerPoint to PDF</li></td>
            <td>1 hours</td>
          </tr>
          <tr>
            <td><li>JPG to PDF</li></td>
            <td>1 hours</td>
          </tr>
          <tr>
            <td><li>PDF to Word</li></td>
            <td>1 hours</td>
          </tr>
          <tr>
            <td><li>PDF to Excel</li></td>
            <td>1 hours</td>
          </tr>
          <tr>
            <td><li>PDF to PowerPoint</li></td>
            <td>1 hours</td>
          </tr>
          <tr>
            <td><li>PDF to JPG</li></td>
            <td>1 hours</td>
          </tr>
        </tbody>
      </table>
      <p>
        Users bear the sole responsibility for the usage of their own files.
      </p>
      <p>
        CyberPDF is limited to offer users access to their own processed files.
        These files will only remain stored on our servers during the time that
        the download link is available.
      </p>
      <h3>3.2 Content manipulation</h3>
      <p>
        CyberPDF provides all the necessary information to assist the user in
        processing files, and only the User is responsible for contacting CyberPDF
        in case of technical problems. CyberPDF is highly concerned about file
        security, thus its servers have limited access, are secured and
        strategically placed. User will be able to modify his User type or
        cancel his account at any time, by means of the contact form.
      </p>
      <h3>3.3 Responsibility for the content of the files</h3>
      <p>
        CyberPDF does not analyze the content of processed files and thus is not
        responsible for its tools misuse nor copyright infringements which may
        affect third- parties. The User will be responsible before CyberPDF of any
        penalty, sanction, and/or fine which the courts or other competent
        authorities could issue against CyberPDF for noncompliance with any part
        of this Agreement.
      </p>
      <p>
        In particular, users agree to use the Service in conformity with current
        laws and conformant to the rules aforementioned in section 1.1.
      </p>
      <h2>4. Paid Services</h2>
      <h3>4.1 Refunds</h3>
      <p>
        You may cancel your PRO account at any time; however, there are no
        refunds for cancellation. In the event that CyberPDF suspends or
        terminates your account or this Agreement, you understand and agree that
        you shall receive no refund or exchange for any credits, any unused time
        for any portion of the Service.
      </p>
      <h3>4.2 Payment information, taxes</h3>
      <p>
        All information provided by the User in connection with a purchase or
        transaction with the Service must be accurate, complete, and current.
        You agree to pay all charges incurred by users of your payment method
        used in connection with a purchase or with the Service. You will pay
        applicable taxes, if any, relating to your purchases.
      </p>
      <h2>5. No warranty</h2>
      <h3>5.1 General</h3>
      <p>
        Use of the service is at your own risk. To the maximum extent permitted
        by applicable law, the service is provided without warranties of any
        kind. CyberPDF does not warrant that the service will meet your
        requirements; that the service will be available at any particular time
        or location, uninterrupted or secure; that any defects or errors will be
        corrected; or that the service is free of viruses or other harmful
        components. Any content downloaded or otherwise obtained through the use
        of the service is downloaded at your own risk and you will be solely
        responsible for any damage to your computer system or mobile device or
        loss of data that results from such download or your use of the service.
        CyberPDF does not warrant, endorse, guarantee, or assume responsibility
        for any product or service advertised or offered by a third party
        through the CyberPDF service or any hyperlinked website or service, and
        CyberPDF will not be a party to monitor any transaction between you and
        third-party providers.
      </p>
      <h2>6. Limitation of liability</h2>
      <h3>6.1 General</h3>
      <p>
        To the maximum extent permitted by applicable law, in no event shall
        CyberPDF or its employees be liable for any direct, indirect, punitive,
        incidental, special, consequential or exemplary damages, including
        without limitation damages for use, data or other intangible losses,
        arising from or relating to any breach of this agreement. Under no
        circumstances will CyberPDF be responsible for any damage, loss or injury
        resulting from hacking, tampering or other unauthorized access or use of
        the service or your account or the information contained therein.
      </p>

      <h2>7. Governing Law and Dispute Resolution</h2>
      <h3>7.1 General</h3>
      <p>This Agreement will be governed by and construed in accordance with the laws of the Singapore excluding that body of laws known as conflicts of law. The United Nations Convention on Contracts for the International Sale of Goods will not apply. Any legal action or proceeding arising under this Agreement will be brought exclusively in the courts of Singapore and the parties hereby irrevocably consent to the personal jurisdiction and venue therein.</p>

      <h2>8. License of limited use</h2>
      <h3>8.1 General</h3>
      <p>
        CyberPDF is the exclusive owner of all of the rights to the web
        application which allows the functionalities offered online and, in
        particular, the right to total or partial reproduction, by any means,
        and in any form; the translation, adaptation, arrangement, or any other
        transformation of the program and the reproduction of the results of
        such acts; the distribution in any of the forms admitted by law; the
        right to publish through all types of media: analog and digital, online
        and offline; and the right to the program&#x27;s use. The program&#x27;s
        license of use for users does not refer to the Intellectual Property
        rights of the Service, the users remain solely authorized to use Service
        software. For any distinct uses, CyberPDF S.L. must authorize their
        exploitation, as in ceding those rights to third-parties. Therefore, the
        execution, reproduction, exploitation, alteration, distribution, or
        public communication of the totality of the copyright property of CyberPDF
        remain prohibited for uses distinct from those authorized by the current
        Agreement. In particular, it is not permitted to: make copies of the
        program, translate its source code, transform it, or distribute it
        without the precise authorization of CyberPDF. The breach of these
        obligations for the Users may lead to, at the discretion of CyberPDF, the
        relevant claims established by the relevant copyright regulations, the
        suspension of Service, or the termination of the Contract, as
        established in Clause 8.
      </p>
      <h2>9. Intellectual and industrial property rights</h2>
      <h3>9.1 General</h3>
      <p>
        The contents of this site, including the contents, brands, logos,
        drawings, texts, images, databases, codes, and any other material belong
        to CyberPDF or to third-parties who have authorized their use. In a
        general manner, their utilization with commercial ends, their public
        communication or distribution, or any other form of exploitation by any
        process, such as transformation or alteration, all remains prohibited.
        We expressly disclaim liability for consequential damages resulting from
        using or misusing our services.
      </p>
      <h2>10. Termination</h2>
      <h3>10.1 General</h3>
      <p>
        CyberPDF will be capable of unilaterally and, at any point, resolving the
        current Contract in the following cases. a) In the event that the User
        breaches any of the obligations and guarantees established in this
        Agreement. b) If intellectual property rights or any other third-party
        rights are infringed upon. c) If User fails to make the timely payment
        of fees for the Software or the Services. d) If we are required to do so
        by law (for example, if providing software to a specific region becomes
        unlawful) e) If we choose to discontinue the Services or Software, in
        whole or in part, (such as if it becomes impractical for us to provide
        Service or our website becomes censored in a region).
      </p>
      <p>
        The resolution of the Contract will not affect the ability of CyberPDF to
        claim the corresponding damages and losses. Users will be qualified to
        cancel their account, at any point, from their Account page or through
        the contact form available online.
      </p>
      <h2>11. Claims</h2>
      <h3>11.1 General</h3>
      <p>
        In case of claims and complaints stemming from the current Contract, or
        to request information about the Service, the User will be able to
        contact CyberPDF through the online form.
      </p>
      <h2>12. Miscellaneous</h2>
      <h3>12.1 General</h3>
      <p>
        The User will not be able to cede, subrogate, or transmit the rights
        contained in the current Contract to third parties without the previous
        written consent of CyberPDF. Same as the previous point. We are a
        registered trademark, you can&#x27;t copy our brand identity.
      </p>
      <p>
        The offense or delay in the exercise of any right or in the demand for
        the completion of any of the obligations arising from this Contract will
        not constitute a renunciation of that right or demand for the completion
        of the obligation, nor the renunciation of any other rights or demands
        for the completion of obligations. This Contract, including the Privacy
        Policy which will be incorporated for reference into the current
        Contract, constitutes the final, complete, and exclusive agreement
        between the parties in relation to the object of the Contract, and
        substitutes any of the previous agreements or negotiations between said
        parties. If any of the clauses of the present Contract might become null
        due to a contravention of the applicable legislation, said clause will
        be assumed not to be in effect, but will not affect the rest of the
        Contract, which will be assumed to be in full effectivity and validity
        between the parties.
      </p>
      <div style="height: 40px"></div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";

export default {
  name: "TermsConditions",
  components: {},
  setup() {
    let route = useRoute();

    return {
      route,
    };
  },
  created() {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  },
};
</script>

<style scoped>
* {
  font-size: 12px;
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: start;
  font-size: 14px;
  padding-top: 48px;
}

a {
  font-size: 14px;
  color: #626262;
}

.checked {
  color: #454545;
  font-weight: 700;
}

.route-space {
  margin-right: 95px;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto;
}

.route-space img {
  width: 246px;
  height: auto;
  margin-bottom: 40px;
}

ul {
  margin: 0;
  padding-left: 22px;
  font-size: 12px;
}

.not-bottom {
  margin-bottom: 0;
}

h1 {
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 36px;
}

h2 {
  margin-bottom: 18px;
  font-size: 24px;
}

h3 {
  font-size: 18px;
  margin-bottom: 18px;
}

p {
  font-size: 12px;

  margin: 18px 0;
}

@media all and (max-width: 765px) and (min-width: 320px) {
  * {
    font-size: 14px;
  }
  .main-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    box-sizing: border-box;
  }

  .route-space {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    margin: 0 0 32px 0;
    justify-items: center;
  }

  .route-space img {
    grid-row: 1/2;
    grid-column: 1/3;
    margin-bottom: 32px;
  }
  .nav {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    text-align: center;
    padding: 7px 17px;
    width: 100%;
  }
  .route-space .nav-left {
    border-right: none;
    border-radius: 4px 0 0 4px;
  }
  .route-space .nav-right {
    border-radius: 0 4px 4px 0;
    border-left: none;
  }
  .route-space .checked {
    background: #454545;
    color: #FFFFFF;
    font-weight: 400;
  }
  .page-title {
    line-height: 1;
  }

  h1 {
    font-size: 32px;
    margin-bottom: 40px;
  }
  h2 {
    line-height: 1;
    padding-top: 20px;
  }
  p {
    font-size: 14px;
    margin: 20px 0;
    line-height: 1.4;
  }

}

@media all and (max-width: 1020px) and (min-width: 765px) {
  .main-content {
    box-sizing: border-box;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .page {
    max-width: 60%;
  }
  .route-space .nav:not(:first-of-type) {
    margin-top: 14px;
  }
}
@media all and (min-width: 1020px) {
  .route-space .nav:not(:first-of-type) {
    margin-top: 14px;
  }
}
</style>
