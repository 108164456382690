<template>
  <div
    class="plans"
    @click="router.push({ name: 'product', query: { from: route.name } })"
  >
    <div class="text flex">
      <h1 class="title" v-html="$t('home.plans.title')"></h1>
      <span class="desc" v-html="$t('home.plans.desc')"></span>
      <button>
        {{ $t("home.plans.buttonText") }}
      </button>
    </div>

    <div class="flex product">
      <span class="title">{{ pro.name }}</span>
      <hr />
      <div>
        <span class="price"
          >{{ $t("productPage.unit") }}{{ pro.price[locale] }}</span
        ><span>&nbsp;/&nbsp;{{ $t("home.plans.superiorProductPeriod") }}</span>
      </div>
      <span class="desc" v-html="$t('home.plans.superiorProductDesc')"></span>
    </div>
    <button class="short-button">
      {{ $t("home.plans.buttonText") }}
    </button>

    <div class="img flex">
      <img src="@/assets/images/home/plans-3.png" alt="load error" />
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import products from "@/store/product";

export default {
  name: "HomeShowPlan",
  components: {},
  setup() {
    let router = useRouter();
    let route = useRoute();

    let pro = computed(() => {
      return products.filter((p) => {
        return p.id === "pro_30d";
      })[0];
    });

    let { locale } = useI18n();

    return { router, route, locale, pro };
  },
  created() {},
};
</script>

<style scoped>
.plans {
  cursor: pointer;
  box-sizing: border-box;
  background: #f2f9ff;
  border-radius: 2px;
  justify-content: center;
}
.plans .title {
  font-weight: 700;
  font-size: 24px;

  text-align: start;
}
.plans .text {
  margin-right: 40px;
}
.plans .text .desc {
  margin-bottom: 32px;
  margin-top: 14px;
}

.plans img {
  width: 225px;
  height: 114px;
}
.plans .text,
.plans .product {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}
.product {
  font-size: 14px;
  padding: 26px 20px 0 16px;
  height: 172px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 40px;
}
.product .title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
}
.product .price {
  font-weight: 700;
  font-size: 24px;
}
.product .desc {
  margin-top: 14px;
}
.text button {
  font-size: 14px;
  white-space: nowrap;
}
.text .desc,
.text button {
  font-size: 14px;
}
.product .desc {
  font-size: 14px;
  color: #b9b9b9;
}
.product hr {
  margin-top: 14px;
  margin-bottom: 14px;
}
.short-button {
  display: none;
}
hr {
  width: 100%;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .plans {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    padding: 32px 18px 28px 18px;
    justify-items: center;
    margin: 0 26px;
  }
  .plans .img,
  .plans button {
    display: none;
  }
  .plans .text {
    margin: 0;
    text-align: center;
    align-items: center;
  }
  .plans .text .title {
    font-size: 32px;
  }
  .plans .text .desc {
    font-size: 18px;
    text-align: start;
    margin-bottom: 28px;
    margin-top: 24px;
  }
  .plans .product {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }
  .plans .short-button {
    margin-top: 28px;
    display: block;
    width: 220px;
    height: 44px;
    font-size: 16px;
  }
}
@media all and (max-width: 1020px) and (min-width: 640px) {
  .plans {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    margin: 0 26px;
    padding: 32px 18px 28px 18px;
  }
  .plans .text {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 32px;
  }
  .plans .product {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .plans .img {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
  }
}

@media all and (min-width: 1020px) {
  .plans {
    display: flex;
    justify-content: flex-start;
    height: 260px;
    padding: 32px 0 32px 50px;
    width: 100%;
  }
  .plans .text .desc {
    max-width: 245px;
  }
  .plans .product {
    width: 266px;
    height: 196px;
  }
}
</style>
