<template>
  <div class="home">
    <div>
      <span class="name">
        {{
          store.account.first_name || store.account.last_name
            ? i18nReplace($t("head.say"), [
                {
                  userName: `${store.account.first_name || ""} ${
                    store.account.last_name || ""
                  }`,
                },
              ])
            : `${$t("workspacePage.welcome")} ${$t("app")}`
        }}
      </span>
      <span class="time" v-if="payType === 'free'"
        >{{ `${$t("app")} ${$t("workspacePage.free.desc")}` }}&nbsp;&nbsp;
        <a :href="`#/product?from=${route.name}`">{{
          $t("workspacePage.free.to")
        }}</a>
      </span>
      <span class="time" v-if="payType === 'paidAndExpired'"
        >{{
          `${$t("app")} ${$t("workspacePage.paidAndExpired.desc")} ${
            meExpire || ""
          }`
        }}&nbsp;&nbsp;
        <a :href="`#/product?from=${route.name}`">{{
          $t("workspacePage.paidAndExpired.to")
        }}</a>
      </span>
      <span class="time" v-if="payType === 'paid'">
        {{ `${$t("app")} ${$t("workspacePage.paid.desc")}` }}&nbsp;
        <span>{{ meExpire || "" }}</span>
      </span>
    </div>

    <hr />

    <div class="recents">
      <span>{{ $t("workspacePage.recents") || "Recents" }}</span>
      <div class="tools flex">
        <div
          class="item"
          :key="'recents' + index"
          v-for="(item, index) in store.recents"
        >
          <ToolBox
            v-if="store.tools[item]"
            :style="{
              background: store.tools[item].background,
              color: store.tools[item].textColor,
            }"
            :icon="store.tools[item].iconTool"
            :name="
              store.tools[item].type === 'convert'
                ? `${store.files[store.tools[item].from].name} ${$t(
                    'convertPage.to'
                  )} ${store.files[store.tools[item].to].name}`
                : store.tools[item].type === 'split'
                ? `${$t('splitPage.name')} ${
                    store.files[store.tools[item].to].name
                  }`
                : `${$t('mergePage.name')} ${
                    store.files[store.tools[item].to].name
                  }`
            "
            :work="item"
          ></ToolBox>
        </div>
        <div @click.prevent="router.push({ name: 'tool' })">
          <ToolBox
            :style="{
              background: '#EFF0FA',
              color: '#A588F4',
            }"
            :icon="require('@/assets/images/work/all/icon-all-tools.png')"
            :name="`${$t('workspacePage.allTools')}`"
          ></ToolBox>
        </div>
      </div>

      <div class="short-tools flex">
        <div
          class="item"
          :key="'recents-short' + index"
          v-for="(item, index) in store.recents"
        >
          <DetailsToolBox
            :desc-color="store.tools[item].descColor"
            :desc="$t(`tools.toolDescText.${item}`)"
            :style="{
              background: store.tools[item].background,
              color: store.tools[item].textColor,
            }"
            :icon="store.tools[item].iconTool"
            :name="
              store.tools[item].type === 'convert'
                ? `${store.files[store.tools[item].from].name} ${$t(
                    'convertPage.to'
                  )} ${store.files[store.tools[item].to].name}`
                : store.tools[item].type === 'split'
                ? `${$t('splitPage.name')} ${
                    store.files[store.tools[item].to].name
                  }`
                : `${$t('mergePage.name')} ${
                    store.files[store.tools[item].to].name
                  }`
            "
            :work="item"
          ></DetailsToolBox>
        </div>
        <Button @click.prevent="router.push({ name: 'tool' })">
          {{ $t("workspacePage.allTools") }}
        </Button>
      </div>
    </div>
    <div style="height: 28px"></div>
    <!--        <div>-->
    <!--          <div>-->
    <!--            <table>-->
    <!--              <tbody>-->
    <!--                <tr>-->
    <!--                  <th>{{ $t("workspacePage.table.name") || "Name" }}</th>-->
    <!--                  <th>{{ $t("workspacePage.table.size") || "Size" }}</th>-->
    <!--                  <th>{{ $t("workspacePage.table.date") || "Date" }}</th>-->
    <!--                  <th>{{ $t("workspacePage.table.action") || "Action" }}</th>-->
    <!--                </tr>-->
    <!--                <tr v-for="(file, index) in meFiles" :key="`me-file-${index}`">-->
    <!--                  <td class="file-name">-->
    <!--                    <img-->
    <!--                      class="name-icon"-->
    <!--                      :src="getFileIcon(file.metadata.ofn)"-->
    <!--                      alt="load error"-->
    <!--                    />-->
    <!--                    <span :title="file.metadata.ofn">{{-->
    <!--                      utils.getOmittedFilename(file.metadata.ofn)-->
    <!--                    }}</span>-->
    <!--                  </td>-->
    <!--                  <td>{{ utils.renderSize(file.size) }}</td>-->
    <!--                  <td>{{ timeFormat(file.updated) }}</td>-->
    <!--                  <td class="action">-->
    <!--                    <img src="@/assets/images/work/icon_exception.png" alt="load error" />-->
    <!--                  </td>-->
    <!--                </tr>-->
    <!--              </tbody>-->
    <!--            </table>-->
    <!--          </div>-->
    <!--        </div>-->
  </div>
</template>

<script>
import { reactive, inject } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import ToolBox from "../components/ToolBox";
import Button from "../components/Button";
import DetailsToolBox from "../components/DetailsToolBox";
import utils from "../util/utils";
import { i18nReplace } from "@/language/i18n";

export default {
  name: "Workspace",
  components: { ToolBox, DetailsToolBox, Button },
  setup() {
    let router = useRouter();
    let route = useRoute();
    let useStores = useStore();
    let store = useStores.state;

    let meFiles = reactive([]);
    function load() {
      utils.getFilesOfMe(`${store.account.code}`).then((res) => {
        res.data.forEach((e) => {
          meFiles.push(e);
        });
      });
    }

    function getFileIcon(file) {
      let data = "";
      Object.keys(store.files).forEach((key) => {
        const _incs = store.files[key].formatIncluded;
        if (_incs && Object.values(_incs).includes(utils.getFileSuffix(file))) {
          data = store.files[key].iconSmall;
        }
      });
      return data;
    }

    const meExpire = inject("meExpire");
    const timeFormat = inject("timeFormat");
    const payType = inject("payType");

    return {
      route,
      router,
      meExpire,
      getFileIcon,
      payType,
      load,
      meFiles,
      store,
      utils,
      timeFormat,
      i18nReplace,
    };
  },
  created() {
    // this.load();
  },
};
</script>

<style scoped>
.home,
.home > div {
  width: 100%;
}
.home {
  padding-top: 40px;
}
hr {
  margin: 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid #d9d9d9;
}
.name {
  margin-bottom: 14px;
  display: block;
}
.name,
.recents {
  font-weight: 600;
  font-size: 22px;
}
.time {
  /*font-weight: 600;*/
  font-size: 14px;
}
.time span {
  font-weight: 400;
}
.time a {
  text-decoration: #00a3ff;
  color: #00a3ff;
  text-decoration-line: underline;
}
hr {
  margin-bottom: 28px;
  margin-top: 14px;
}
.tools .item:not(:last-child) {
  margin-right: 24px;
}
.tools {
  justify-content: flex-start;
  margin-top: 14px;
}
.short-tools {
  display: none;
  margin-top: 18px;
  flex-direction: column;
}
.short-tools .item {
  margin-bottom: 14px;
  width: 100%;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  width: 766px;
}
td {
  padding: 11px 0 0 0;
  margin: 0;
  font-size: 14px;
}
td:not(:first-child) {
  text-align: center;
}
th:first-child {
  padding-left: 40px;
  text-align: start;
}
th:last-child {
  padding-right: 39px;
  text-align: end;
}
th {
  height: 32px;
  font-weight: 700;
  font-size: 14px;

  background: #f5f5f5;
  border-radius: 2px;
}
.action {
  text-align: center;
}
.action img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.file-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.name-icon {
  width: 28px;
  height: 28px;
  margin-right: 9px;
}

@media all and (max-width: 765px) and (min-width: 320px) {
  .home {
    padding: 24px;
    box-sizing: border-box;
  }
  .name {
    font-size: 24px;
  }
  .time {
    margin-bottom: 18px;
    font-size: 16px;
  }
  .all-item,
  .all-item > div {
    width: 100%;
  }
  .all-item > div {
    height: 100px;
    font-size: 18px !important;
  }
  hr {
    margin-top: 28px;
    margin-bottom: 24px;
  }
  .tools {
    display: none;
  }
  .short-tools {
    display: flex;
  }
  .recents {
    font-size: 18px;
  }
  :deep(button) {
    font-size: 16px !important;
  }
}
@media all and (max-width: 1020px) and (min-width: 765px) {
  .home {
    padding: 5%;
    box-sizing: border-box;
  }
}
</style>
