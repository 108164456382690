<template>
  <button @click="router.push({ name: 'tool' })">
    <slot></slot>
  </button>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "Banner",
  components: {},
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  setup() {
    let router = useRouter();
    return { router };
  },
  created() {},
};
</script>

<style scoped>
@media all and (max-width: 640px) and (min-width: 320px) {
  button {
    width: 220px;
    height: 44px;
    font-size: 16px !important;
  }
}
</style>
