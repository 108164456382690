export default {
  domains: {
    gas: `${process.env.VUE_APP_HOST_GAS}`,
    pop: `${process.env.VUE_APP_HOST_POP}`,
  },
  stripe: {
    key: `${process.env.VUE_APP_STRIPE_PUBLIC_KEY}`,
    card: {},
    grabpay: {
      returnUrl: `${process.env.VUE_APP_STRIPE_GRABPAY_RETURN_URL}`,
      paytab: `${process.env.VUE_APP_STRIPE_GRABPAY_PAY_TAB}` || "_self", // `_self` or '_blank'
    },
    alipay: {
      returnUrl: `${process.env.VUE_APP_STRIPE_ALIPAY_RETURN_URL}`,
      paytab: `${process.env.VUE_APP_STRIPE_ALIPAY_PAY_TAB}` || "_self", // `_self` or '_blank'
    },
  },
};
