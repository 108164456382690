<template>
  <div class="tool-box" @click="toWork(work)">
    <img :src="icon" alt="load error" />
    <div class="text">
      <div>{{ name }}</div>
      <span :style="{ color: descColor }">
        {{ desc }}
      </span>
    </div>
  </div>
</template>

<script>
// import { toRefs, reactive, ref } from 'vue'

import { useStore } from "vuex";
import utils from "@/util/utils";

export default {
  name: "DetailsToolBox",
  components: {},
  props: {
    icon: String,
    name: String,
    work: String,
    descColor: String,
    desc: String,
  },
  setup() {
    let useStores = useStore();
    let store = useStores.state;
    const _host =
      process.env.NODE_ENV === "production"
        ? `https://${process.env.VUE_APP_DOMAIN}/`
        : `http://${process.env.VUE_APP_DOMAIN}:3000/`;
    function toWork(work) {
      utils.changeRecents(store.account.code, work);
      window.location.href = `${_host}${store.tools[work].local}.html`;
    }

    return {
      toWork,
    };
  },
  created() {},
};
</script>

<style scoped>
.tool-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 12px 14px 12px 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
}
.tool-box img {
  width: 46px;
  height: 46px;
  margin-right: 4px;
}
.text {
  white-space: break-spaces;
  text-align: start;
}
.text > div {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
}
.text > span {
  font-weight: normal;
  font-size: 12px;
}

@media all and (max-width: 765px) and (min-width: 320px) {
  .tool-box {
    min-height: 100px;
    padding: 12px 14px;
  }
  .text > div {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 6px;
  }
  .text > span {
    font-size: 16px;
    line-height: 18px;
  }
  .tool-box img {
    margin: auto 8px auto 0;
  }
}

@media all and (min-width: 1020px) {
  .tool-box {
    width: 210px;
    min-height: 88px;
  }
}
</style>
