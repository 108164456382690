<template>
  <div class="flex" @click="next(id)" :style="{ backgroundColor }">
    <span class="days">{{ days }} {{ $t("productPage.days") }}</span>
    <div class="price-parent">
      <div>
        <span class="price" :style="{ color: textColor }">
          {{ $t("productPage.unit") }}{{ price }}
        </span>
        <span class="period"
          >&nbsp;/&nbsp;{{ $t("productPage.showPeriod") }}</span
        >
      </div>
      <div>
        <span :style="{ color: billedTextColor }">{{ billedText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallProductCard",
  components: {},
  props: {
    id: {
      type: String,
      require: true,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    days: {
      type: String,
      default: "0",
    },
    price: {
      type: String,
      default: "**",
    },
    billedText: {
      type: String,
      default: "",
    },
    billedTextColor: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    function next(id) {
      context.emit("go", id);
    }

    return { next };
  },
  created() {},
};
</script>

<style scoped>
.flex {
  width: 80%;
  min-width: 327px;
  height: 76px;
  cursor: pointer;
  justify-content: space-between;
  padding: 13px 16px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  text-align: end;
}
.days {
  font-weight: 700;
  font-size: 18px;
}
.price {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

@media all and (max-width: 765px) and (min-width: 320px) {
  .flex {
    width: 100%;
    min-width: 100%;
  }
}
</style>
