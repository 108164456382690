<template>
  <div class="tool-block">
    <div class="item" :key="key" v-for="(item, key) in targetPdfTools">
      <ToolBox
        :desc-color="item.descColor"
        :desc="$t(`tools.toolDescText.${key}`)"
        :style="{ background: item.background, color: item.textColor }"
        :icon="item.iconTool"
        :name="`${store.files[item.from].name} ${$t('convertPage.to')} ${
          store.files[item.to].name
        }`"
        :work="key"
      ></ToolBox>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import ToolBox from "./DetailsToolBox";
export default {
  name: "OneWayToolBlock",
  components: { ToolBox },
  props: {},
  setup() {
    let useStores = useStore();
    let store = useStores.state;

    let targetPdfTools = {};
    for (const toolsKey in store.tools) {
      const item = store.tools[toolsKey];
      if (item.from === "pdf" && item.type === "convert") {
        targetPdfTools[toolsKey] = item;
      }
    }

    return {
      store,
      targetPdfTools,
    };
  },
  created() {},
};
</script>

<style scoped>
.tool-block {
  justify-content: center;
  display: grid;
  grid-column-gap: 24px;
}
.item:hover {
  /*background-color: #3d99f5;*/
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .tool-block {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    grid-row-gap: 14px;
    padding: 0 24px;
  }
}

@media all and (max-width: 765px) and (min-width: 640px) {
  .tool-block {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    grid-row-gap: 14px;
    padding: 0 24px;
  }
}

@media all and (max-width: 1020px) and (min-width: 765px) {
  .tool-block {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 24px;
    padding: 0 24px;
  }
  .item {
    width: 330px;
  }
}
@media all and (min-width: 1020px) {
  .tool-block {
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto;
  }
}
</style>
