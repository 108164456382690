<template>
  <div class="title">{{ $t("productPage.title") }}</div>
<!--  <div class="toggle">-->
<!--    <div-->
<!--      class="toggle-left"-->
<!--      :class="{ selected: selected === 'monthly' }"-->
<!--      @click="selected = 'monthly'"-->
<!--    >-->
<!--      {{ $t("productPage.shortTermButtonText") }}-->
<!--    </div>-->
<!--    <div-->
<!--      class="toggle-right"-->
<!--      :class="{ selected: selected === 'yearly' }"-->
<!--      @click="selected = 'yearly'"-->
<!--    >-->
<!--      {{ $t("productPage.longTermButtonText") }}-->
<!--    </div>-->
<!--  </div>-->

  <div class="flex view" v-if="selected === 'monthly'">
    <div class="flex">
      <FreeProduct></FreeProduct>
    </div>
    <div v-for="p in lowProducts" :key="p.id + p.name" class="flex">
      <Product
        :id="p.id"
        :name="p.name"
        :price="p.price[locale]"
        :billed="$t(`productPage.${p.id}.billed`)"
        :butText="$t(`productPage.${p.id}.butText`)"
        :rules="getRules(p.id)"
        :textColor="p.textColor"
        :backgroundColor="p.backgroundColor"
        :divideLineColor="p.divideLineColor"
        :billedColor="p.billedColor"
        :totalPrice="Math.round(Number.parseFloat(p.totalPrice[locale])).toString()"
        @go="toBuy"
      />
    </div>
  </div>
  <div class="flex view" v-if="selected === 'yearly'">
    <div class="flex">
      <FreeProduct></FreeProduct>
    </div>
    <div v-for="p in upProducts" :key="p.id + p.name" class="flex">
      <Product
        :id="p.id"
        :name="p.name"
        :price="p.price[locale]"
        :billed="$t(`productPage.${p.id}.billed`)"
        :butText="$t(`productPage.${p.id}.butText`)"
        :rules="getRules(p.id)"
        :textColor="p.textColor"
        :backgroundColor="p.backgroundColor"
        :divideLineColor="p.divideLineColor"
        :billedColor="p.billedColor"
        :totalPrice="Math.round(Number.parseFloat(p.totalPrice[locale])).toString()"
        @go="toBuy"
      />
    </div>
  </div>

  <div class="short-info free product-text">
    <ProductText
      :id="free.id"
      :name="free.name"
      :rules="getRules(free.id)"
      @go="toBuy"
    ></ProductText>
  </div>
  <div class="short-info product-text">
    <ProductText
      :id="pro[0].id"
      :name="pro[0].name"
      :rules="getRules(pro[0].id)"
    ></ProductText>
  </div>

  <div class="flex cards">
    <div class="item-card" v-for="p in pro" :key="`product-card-${p.id}`">
      <SmallProductCard
        :id="p.id"
        :textColor="p.textColor"
        :backgroundColor="p.backgroundColor"
        :price="p.price[locale]"
        :billedText="getBilledText(p.id, p.totalPrice[locale])"
        :billedTextColor="p.billedColor"
        :days="p.days"
        @go="toBuy"
      >
      </SmallProductCard>
    </div>
  </div>

  <ChooseUs class="choose-us"></ChooseUs>

  <ColumnToolsAndSiteMap :showTool="false"></ColumnToolsAndSiteMap>
</template>

<script>
import { ref, computed, inject } from "vue";
import Product from "../components/Product";
import FreeProduct from "../components/FreeProduct";
import ProductText from "../components/ProductText";
import SmallProductCard from "../components/SmallProductCard";
import ChooseUs from "../components/ChooseUs";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import products from "../store/product";
import ColumnToolsAndSiteMap from "../components/ColumnToolsAndSiteMap";

import { useI18n } from "vue-i18n";
import { i18nReplace } from "@/language/i18n";
import utils from "@/util/utils";

export default {
  name: "ProductPage",
  components: {
    Product,
    ChooseUs,
    FreeProduct,
    ProductText,
    SmallProductCard,
    ColumnToolsAndSiteMap,
  },
  setup() {
    let router = useRouter();
    let route = useRoute();
    let store = useStore().state;
    const { locale, t } = useI18n();

    let freeProduct = computed(() => {
      return products.filter((p) => {
        return !p.period;
      })[0];
    });
    let lowProducts = computed(() => {
      return products.filter((p) => {
        return p.period === "month" //|| p.period === "quarterly";
      });
    });
    let upProducts = computed(() => {
      return products.filter((p) => {
        return p.period === "yearly" || p.period === "halfYearly";
      });
    });

    let mobileHeadTopup = inject("mobileHeadTopup")

    function toBuy(id) {
      let q = { from: route.query.from };
      if (route.query.work) q.work = route.query.work;
      if (store.isLogin) {
        q.proId = id;
        router.push({ name: "pay", query: q });
      } else {
        if (!mobileHeadTopup()){
          router.push({ name: "login", query: { from: route.query.from } });
        }
      }
    }

    let selected = ref("monthly");

    function getRules(id) {
      return t(`productPage.${id}.rules`).split(",");
    }

    let free = computed(() => {
      return products.find((p) => p.id === "pro_0");
    });
    let pro = computed(() => {
      return products.filter((p) => p.id === "pro_30d");
    });
    function getBilledText(id, totalPrice) {
      return i18nReplace(t(`productPage.${id}.cardBilledtext`), [
        {
          totalPrice: `${t("productPage.unit")}${utils.calculateDecimal(
            totalPrice
          )}`,
        },
      ]);
    }

    return {
      store,
      locale,
      toBuy,
      freeProduct,
      upProducts,
      lowProducts,
      selected,
      products,
      getRules,
      free,
      pro,
      getBilledText,
    };
  },
  created() {},
};
</script>

<style scoped>
hr {
  margin: 0;
  padding: 0;
  height: 1px;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
}
.title {
  margin-top: 40px;
  margin-bottom: 34px;
  font-weight: 700;
  font-size: 24px;
}
.toggle {
  color: #7d7d7d;
  font-size: 14px;
  font-weight: normal;
  background: #f0f0f0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
}
.toggle > div {
  padding: 5px 20px;
  cursor: pointer;
  min-width: 6em;
  text-align: center;
}
.toggle .selected {
  background: #3e3e3e;
  color: #ffffff;
  cursor: default;
}
.toggle .toggle-left {
  border-radius: 2px 0 0 2px;
}
.toggle .toggle-right {
  border-radius: 0 2px 2px 0;
}
.view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 32px;
}

.choose-us {
  margin: 24px auto 40px auto;
}
:deep(.title) {
  font-size: 26px;
}

.short-info,
.cards {
  display: none;
}
.short-info {
  flex-direction: column;
  margin: 0 24px 24px 24px;
  box-sizing: border-box;
  width: calc(100% - 48px);
}

.free {
  padding-bottom: 32px;
  border-bottom: 1px solid #e9e9e9;
}
.cards {
  flex-direction: column;
  padding-bottom: 32px;
  border-bottom: 1px solid #e9e9e9;
}
.cards .item-card:not(:last-child) {
  margin-bottom: 14px;
}

@media all and (max-width: 800px) and (min-width: 320px) {
  .title {
    margin-bottom: 24px;
  }
  .toggle,
  .view {
    display: none;
  }
  .short-info,
  .cards {
    display: flex;
    align-items: center;
  }
}
@media all and (max-width: 765px) and (min-width: 320px) {
  .short-info {
    align-items: flex-start;
  }
  .cards {
    width: calc(100% - 48px);
    margin: 0 24px;
    box-sizing: border-box;
  }
  .cards .item-card {
    width: 100%;
  }
}
</style>
