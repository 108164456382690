<template>
  <div class="flex contact">
    <div class="title">{{ $t("contact.name") }}</div>
    <div class="flex contact-content">
      <div class="contact-info flex">
        <div>

          <div class="company flex">{{company}}</div>
          <div class="email flex">
            <img class="icon" src="@/assets/images/contact/icon-email.svg" alt="">
            <a :href="`mailto:${email}`">{{ email }}</a>
          </div>
          <div class="flex">
            <img class="icon" src="@/assets/images/contact/icon-address.svg" alt="">
            <span v-html="$t('contact.address')"></span>
          </div>

        </div>

      </div>

      <div class="contact-map">
        <GoogleMap
          api-key="AIzaSyA3G_Y5vH6TzSaQC6WvC3GMVVrsb4WLJ0c"
          style="width: 100%; height: 148px"
          :center="center"
          :zoom="15"
        >
          <Marker :options="{ position: center }" />
        </GoogleMap>
      </div>

    </div>
  </div>
  <ColumnToolsAndSiteMap :showTool="false"></ColumnToolsAndSiteMap>
</template>

<script>
import ColumnToolsAndSiteMap from "../components/ColumnToolsAndSiteMap";
import { ref } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
export default {
  name: "Contact",
  components: { ColumnToolsAndSiteMap, GoogleMap, Marker },
  setup() {
    const email = ref(`${process.env.VUE_APP_EMAIL}`);
    const company = ref(`${process.env.VUE_APP_COMPANY}`);
    const center = { lat: 1.2814149, lng: 103.8488298 }
    return {
      email,
      company,
      center
    };
  },
  created() {},
};
</script>

<style scoped>
.contact {
  padding: 40px 0;
  flex-direction: column;
  font-size: 14px;
}
.title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center;
}
.contact-info {
  margin-right: 50px;
  justify-content: flex-start;
}
.contact-info .flex {
  justify-content: flex-start;
}
.company {
  margin-bottom: 18px;
  font-weight: 700;
  font-size: 18px;
}
.email {
  margin-bottom: 18px;
}
.contact-map {
  width: 358px;
  height: 148px;
}
.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

@media all and (max-width: 765px) and (min-width: 320px) {
  .contact {
    font-size: 16px;
  }
  .title {
    margin-bottom: 24px;
  }
  .contact-map {
    width: 327px;
    height: 148px;
    margin-top: 24px;
  }
  .contact-content {
    flex-direction: column;
  }
  .email {
    margin-bottom: 16px;
  }
}
@media all and (min-width: 765px) {

}

</style>
