<template>
  <div class="profile">
    <div v-if="saveStatus !== ''" class="mask flex" @click="saveStatus = ''">
      <div v-if="saveStatus === 'ok'" class="save-off flex">
        <img src="@/assets/images/icon_success.svg" alt="load error" />
        <span>{{ $t("profilePage.saveSuccessDesc") }}</span>
      </div>
      <div v-if="saveStatus === 'err'" class="save-off flex">
        <img src="@/assets/images/icon_failed.png" alt="load error" />
        <span>{{ $t("profilePage.saveErrorDesc") }}</span>
      </div>
    </div>
    <table class="content-row">
      <tbody>
        <tr class="title">
          <td>
            <div>{{ $t("profilePage.name") }}</div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="slipt-14">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="name">
          <td>
            <span>{{ $t("profilePage.firstName") }}</span>
          </td>
          <td>
            <input
              :class="{ isChange: isChangeFirst }"
              type="text"
              v-model="firstName"
              maxlength="16"
            />
          </td>
          <td></td>
          <td>
            <button @click="updateAccountName">
              {{ $t("profilePage.save") }}
            </button>
          </td>
        </tr>
        <tr style="height: 14px"></tr>

        <tr class="name">
          <td>
            <span>{{ $t("profilePage.lastName") }}</span>
          </td>
          <td>
            <input
              :class="{ isChange: isChangeLast }"
              type="text"
              v-model="lastName"
              maxlength="16"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr style="height: 14px"></tr>
        <tr class="email">
          <td>
            <span>{{ $t("email.name") }}</span>
          </td>
          <td>
            <span>{{ meEmail }}</span>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr style="height: 14px"></tr>
        <tr class="slipt1">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="security title">
          <td>
            <div>
              {{ $t("profilePage.security") }}
            </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="slipt-14">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="password">
          <td>
            <span>{{ $t("password.name") }}</span>
          </td>
          <td><input readonly type="password" value="123456" /></td>
          <td></td>
          <td>
            <button @click="toReset">
              {{ $t("profilePage.changePassword") }}
            </button>
          </td>
        </tr>
        <tr class="slipt2">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="slipt3" style="height: 28px">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        <tr class="plan title">
          <td>
            <div>
              {{ $t("profilePage.plan") }}
            </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="slipt-14">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="plan-info">
          <td colspan="2" class="plan-desc">
            <div v-if="payType === 'paid'">
              <span>{{
                `${$t("app")} ${$t("plan.paid.tag")} ${meExpire || ""}`
              }}</span>
              <br />
              <span>{{ $t("plan.paid.desc") }}</span>
            </div>
            <div v-if="payType === 'paidAndExpired'">
              <span>{{
                `${$t("app")} ${$t("plan.paidAndExpired.tag")} ${
                  meExpire || ""
                }`
              }}</span>
              <br />
              <span>{{ $t("plan.paidAndExpired.desc") }}</span>
            </div>
            <div v-if="payType === 'free'">
              <span>{{ `${$t("app")} ${$t("plan.free.tag")}` }}</span>
              <br />
              <span>{{ $t("plan.free.desc") }}</span>
            </div>
          </td>
          <td>
            <button @click="toProduct" v-if="payType === 'paidAndExpired'">
              {{ $t("workspacePage.paidAndExpired.to") }}
            </button>
          </td>
          <td>
            <button @click="toProduct" v-if="payType === 'free'">
              {{ $t("workspacePage.free.to") }}
            </button>

            <button @click="toOrderPage" v-if="payType === 'paidAndExpired'">
              {{ $t("workspacePage.paidAndExpired.to2") }}
            </button>
            <button @click="toOrderPage" v-if="payType === 'paid'">
              {{ $t("workspacePage.paid.to") }}
            </button>
          </td>
        </tr>

        <tr style="height: 28px">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <div class="content-col">
      <div>
        <div class="title">{{ $t("profilePage.name") }}</div>
        <div class="name">
          <div>{{ $t("profilePage.firstName") }}</div>
          <input
           :class="{ isChange: isChangeFirst }"
           type="text"
           v-model="firstName"
           maxlength="16"
          >
        </div>
        <div class="name">
          <div>{{ $t("profilePage.lastName") }}</div>
          <input
            :class="{ isChange: isChangeLast }"
            type="text"
            v-model="lastName"
            maxlength="16"
          >
        </div>
        <div class="flex">
          <span class="email-name">{{ $t("email.name") }}</span>
          <span>{{ meEmail }}</span>
        </div>
        <div>
          <button @click="updateAccountName">
            {{ $t("profilePage.save") }}
          </button>
        </div>
      </div>

      <div>
        <div class="title">{{ $t("profilePage.security") }}</div>
        <div class="flex password">
          <span class="password-name">{{ $t("password.name") }}</span>
          <input readonly type="password" value="123456" />
        </div>
        <div>
          <button @click="toReset">
            {{ $t("profilePage.changePassword") }}
          </button>
        </div>
      </div>

      <div>
        <div class="title">{{ $t("profilePage.plan") }}</div>
          <div class="plan-desc">

            <div v-if="payType === 'paid'">
              <span>{{
                  `${$t("app")} ${$t("plan.paid.tag")} ${meExpire || ""}`
                }}</span>
              <br />
              <span>{{ $t("plan.paid.desc") }}</span>
            </div>

            <div v-if="payType === 'paidAndExpired'">
              <span>{{
                  `${$t("app")} ${$t("plan.paidAndExpired.tag")} ${
                    meExpire || ""
                  }`
                }}</span>
              <br />
              <span>{{ $t("plan.paidAndExpired.desc") }}</span>
            </div>

            <div v-if="payType === 'free'">
              <span>{{ `${$t("app")} ${$t("plan.free.tag")}` }}</span>
              <br />
              <span>{{ $t("plan.free.desc") }}</span>
            </div>

          </div>


          <button @click="toProduct" v-if="payType === 'free'">
            {{ $t("workspacePage.free.to") }}
          </button>

          <button @click="toProduct" v-if="payType === 'paidAndExpired'">
            {{ $t("workspacePage.paidAndExpired.to") }}
          </button>
          <button @click="toOrderPage" v-if="payType === 'paidAndExpired'">
            {{ $t("workspacePage.paidAndExpired.to2") }}
          </button>

          <button @click="toOrderPage" v-if="payType === 'paid'">
            {{ $t("workspacePage.paid.to") }}
          </button>
      </div>

    </div>
  </div>
</template>

<script>
import { ref, watch, inject } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import utils from "../util/utils";
import Button from "@/components/Button";

export default {
  name: "Profile",
  components: { Button },
  setup() {
    let useStores = useStore();
    let store = useStores.state;
    let meEmail = ref(store.account.email);
    let firstName = ref(store.account.first_name);
    let lastName = ref(store.account.last_name);
    let isChangeFirst = ref(false);
    let isChangeLast = ref(false);
    let _first = store.account.first_name;
    let _last = store.account.last_name;
    let saveStatus = ref("");
    let buttonStateDuration = ref(1000);

    watch(store.account, () => {
      firstName.value = store.account.first_name;
      lastName.value = store.account.last_name;
      meEmail.value = store.account.email;
      _first = store.account.first_name;
      _last = store.account.last_name;
    });

    function popup(status) {
      saveStatus.value = status;
      window.setTimeout(function () {
        saveStatus.value = "";
      }, buttonStateDuration.value);
    }

    watch(
      [firstName, lastName],
      (newValue) => {
        isChangeFirst.value = _first !== newValue[0];
        isChangeLast.value = _last !== newValue[1];
      },
      { immediate: true }
    );

    function updateAccountName() {
      if (!isChangeFirst.value && !isChangeLast.value) return;
      utils
        .updateAccount({
          first_name: firstName.value,
          last_name: lastName.value,
        })
        .then((res) => {
          popup("ok");
          _first = res.data.first_name;
          _last = res.data.last_name;
          isChangeFirst.value = firstName.value !== res.data.first_name;
          isChangeLast.value = lastName.value !== res.data.last_name;
          useStores.dispatch("account/init");
        })
        .catch((e) => {
          console.log(e);
          popup("err");
        });
    }

    let router = useRouter();
    let route = useRoute();

    function toReset() {
      router.push({ name: "changePasswd" });
    }

    function toOrderPage() {
      router.push({ name: "order" });
    }
    function toProduct() {
      router.push({ name: "product", query: { from: route.name } });
    }
    const meExpire = inject("meExpire");
    const payType = inject("payType");

    return {
      buttonStateDuration,
      saveStatus,
      meExpire,
      payType,
      meEmail,
      toReset,
      isChangeFirst,
      isChangeLast,
      firstName,
      lastName,
      store,
      updateAccountName,
      toOrderPage,
      toProduct,
    };
  },
  created() {
    this.buttonStateDuration = this.$buttonStateDuration;
  },
};
</script>

<style scoped>
.mask {
  flex-direction: column;
}
.save-off {
  font-size: 14px;
  flex-direction: column;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 8px;
  min-width: 164px;
  min-height: 92px;
  padding: 18px 18px 0 18px;
  box-sizing: border-box;
}
.save-off img {
  width: 28px;
  height: 28px;
  margin-bottom: 10px;
}
td {
  padding: 0;
  height: 100%;
  margin: 0px auto;
}

input:focus,
.isChange {
  color: #454545;
}

input {
  box-shadow: none;
  outline: none;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  -webkit-user-select: text !important;
  color: #7d7d7d;
}

.profile {
  margin-top: 62px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title td {
  height: 30px;
}
.title td div {
  font-weight: bold;
  font-size: 22px;
}

.name input {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  height: 48px;
  width: 360px;
  padding: 16px;
}

table tbody .slipt2 td {
  height: 20px;
}
table tbody .slipt1 td,
table tbody .slipt3 td {
  height: 28px;
}
table tbody .slipt-14 td {
  height: 14px;
}
table tbody .plan-desc span {
  line-height: 20px;
}
table tbody .plan-info td:not(:first-child) {
  text-align: end;
}
table tbody .slipt1 td {
  border-top: 1px solid #d9d9d9;
}

table tbody .slipt2 td {
  border-bottom: 1px solid #d9d9d9;
}

.email td:not(:first-child) {
  padding-left: 6px;
  color: #7d7d7d;
  font-size: 14px;

  font-weight: 400;
}

.password input {
  border: none;
  letter-spacing: 10px;
  max-width: 50%;
  width: 50%;
  color: #7d7d7d !important;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

table tbody tr td {
  height: 48px;
}

table tbody tr td:nth-of-type(2) {
  text-align: start;
}

table tbody tr td:last-child {
  text-align: end;
}

table tbody tr td:first-child {
  text-align: start;
  font-size: 14px;

  font-weight: 400;
  padding-right: 14px;
}

.content-col {
  display: none;
  font-size: 16px;
  grid-template-columns: auto;
  box-sizing: border-box;
  padding: 0 24px;
  margin-bottom: 8px;
}

.content-col > div {
  padding-bottom: 32px;
}
.content-col > div:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}

.content-col .title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
  margin-top: 24px;
}
.content-col .name input {
  margin: 8px 0 14px 0;
  width: 100%;
  box-sizing: border-box;
  height: 44px;
}

.content-col .flex {
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.content-col .email-name {
  margin-right: 28px;

}
.content-col .password-name {
  margin-right: 18px;
  white-space: nowrap;
}

.content-col button {
  margin-top: 24px;
  width: 100%;
  height: 44px;
  font-size: 16px;
}


@media all and (max-width: 630px) and (min-width: 320px) {
  .profile {
    margin-top: 8px;
  }
  .content-col {
    display: grid;
  }
  .content-row {
    display: none;
  }
}
@media all and (max-width: 1020px) and (min-width: 630px) {
  .profile {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>
