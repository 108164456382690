<template>
  <div class="choose-us">
    <h1 class="title">{{ $t("home.chooseUs.title") }}</h1>

    <div class="flex block">
      <div class="flex">
        <img src="@/assets/images/home/choose-us-img1.png" alt="load error" />
        <span
          class="i-tag"
          v-html="$t(`home.chooseUs.showBlock.block1.${userMode || 'pc'}.tag`)"
        ></span>
        <span
          class="desc"
          v-html="$t(`home.chooseUs.showBlock.block1.${userMode || 'pc'}.desc`)"
        ></span>
      </div>
      <div class="flex">
        <img src="@/assets/images/home/choose-us-img2.png" alt="load error" />
        <span
          class="i-tag"
          v-html="$t(`home.chooseUs.showBlock.block2.${userMode || 'pc'}.tag`)"
        ></span>
        <span
          class="desc"
          v-html="$t(`home.chooseUs.showBlock.block2.${userMode || 'pc'}.desc`)"
        ></span>
      </div>
      <div class="flex">
        <img src="@/assets/images/home/choose-us-img3.png" alt="load error" />
        <span
          class="i-tag"
          v-html="$t(`home.chooseUs.showBlock.block3.${userMode || 'pc'}.tag`)"
        ></span>
        <span
          class="desc"
          v-html="$t(`home.chooseUs.showBlock.block3.${userMode || 'pc'}.desc`)"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "ChooseUs",
  setup() {
    let userMode = ref(window.document.body.clientWidth < 765 ? "mb" : "pc");
    return {
      userMode,
    };
  },
};
</script>

<style scoped>
.choose-us {
  width: 100%;
}
.title {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 32px;
}
.choose-us .block {
  justify-content: space-between;
}
.choose-us .block > div {
  min-width: 232px;
  height: 190px;
  flex-direction: column;
  justify-content: flex-start;
}
.choose-us img {
  width: 88px;
  height: 78px;
}
.choose-us .i-tag {
  font-size: 18px;
  font-weight: 700;
  margin-top: 14px;
  margin-bottom: 10px;
}
.choose-us .desc {
  font-size: 12px;
  text-align: center;
}

@media all and (max-width: 740px) and (min-width: 320px) {
  .choose-us {
    width: auto;

  }
  .choose-us .block {
    flex-direction: column;
    text-align: start;
    padding: 0 24px;
  }
  .title {
    font-size: 32px;
  }
  .choose-us .desc {
    font-size: 14px;
    text-align: start;
  }
  .choose-us .block .flex {
    display: grid;
    height: auto;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    width: 100%;
  }
  .choose-us .i-tag {
    margin: 0 0 14px 0;
  }
  .choose-us .block .flex:not(:first-child) {
    margin-top: 28px;
  }
  .choose-us .block .flex img {
    margin-right: 26px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}
@media all and (min-width: 500px) {
  .title {
    font-size: 28px;
  }
}
</style>
