<template>
  <div class="flex main">
    <div class="product">
      <div class="title">{{ $t("payPage.title") }}</div>
      <div class="flex mt-28" v-if="product && Object.keys(product).length > 0">
        <div class="l bold">
          {{ product.days || "" }} {{ $t("productPage.days") }}
          {{ $t("payPage.desc") }}
        </div>
        <div class="r">
          <p class="bold">
            {{ $t("productPage.unitText") }}{{ $t("productPage.unit")
            }}{{ product.totalPrice[locale] || "" }}
          </p>
          <p class="sm">
            {{ $t("productPage.unitText") }}{{ $t("productPage.unit")
            }}{{ product.price[locale] || "" }} /
            {{ $t("productPage.showPeriod") }}
          </p>
        </div>
      </div>
    </div>

    <div class="pay-type flex mt-24" @click="showCardForm">
      <div>
        <img
          class="icon-card"
          src="@/assets/images/pay/icon-pay-card.png"
          alt="load error"
        />
      </div>
      <div class="r">
        <p class="bold">{{ $t("payPage.creditcards") }}</p>
      </div>
    </div>

    <a
      v-if="grabpayTabLink"
      class="pay-type flex mt-14"
      :href="grabpayTabLink"
      target="_blank"
    >
      <img class="icon-grab" src="@/assets/images/pay/icon-pay-grab.png" />
      <div class="r">
        <p class="bold">{{ $t("payPage.grabpay") }}</p>
      </div>
    </a>
    <div v-else class="pay-type flex mt-14" @click="grabpay">
      <img class="icon-grab" src="@/assets/images/pay/icon-pay-grab.png" />
      <div class="r">
        <p class="bold">{{ $t("payPage.grabpay") }}</p>
      </div>
    </div>

    <a v-if="alipayTabLink" class="pay-type flex mt-14" :href="alipayTabLink">
      <img class="icon-ali" src="@/assets/images/pay/icon-pay-alipay.png" />
      <div class="r">
        <p class="bold">{{ $t("payPage.alipayglobal") }}</p>
        <p class="sm desc">
          {{ $t("payPage.alipaydesc") }}
        </p>
      </div>
    </a>
    <div v-else class="pay-type flex mt-14" @click="alipay">
      <img class="icon-ali" src="@/assets/images/pay/icon-pay-alipay.png" />
      <div class="r">
        <p class="bold">{{ $t("payPage.alipayglobal") }}</p>
        <p class="sm desc">
          {{ $t("payPage.alipaydesc") }}
        </p>
      </div>
    </div>

    <p class="paid-notice mt-14">
      {{ $t("payPage.alreadypaid")
      }}<a href="#workspace" @click="freshMe">{{ $t("payPage.freshmem") }}</a>
    </p>

    <!-- Display a payment form -->
    <div
      v-if="cardFormVisible"
      class="modal"
      :class="{ ready: cardFromReady }"
      @click="closeModal"
    >
      <div class="modal-content" @click.stop="">
        <component
          :is="CardFormName"
          :clientSecret="clientSecret"
          @submit="cardpay"
          @ready="readyHanlder"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import axios from "axios";
import { computed, ref, onMounted } from "vue";
import { useStripe } from "vue-use-stripe";

import config from "../config";
import { getHashSearch } from "../util";
 
import { useLoading } from "../components/Loading";
import products from "../store/product";
import { useRoute, useRouter } from "vue-router";
import Button from "../components/Button.vue";
import CardForm from "../components/CardForm.vue";

export default {
  name: "Pay",
  components: {
    Button,
    CardForm,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    let { locale, t } = useI18n();
    const grabpayTabLink = ref();
    const alipayTabLink = ref();

    const proId = route.query.proId;
    if (!proId) {
      router.push({ name: "product" });
      return;
    }

    const search = getHashSearch() || window.location.search;
    if (config.stripe.grabpay.paytab === "_blank") {
      grabpayTabLink.value = `#paytab${search ? search + '&' : '?'}paytype=grabpay`;
    }
    if (config.stripe.alipay.paytab === "_blank") {
      alipayTabLink.value = `#paytab${search ? search + '&' : '?'}paytype=alipay`;
    }

    const $loading = useLoading({
      loader: "spinner",
      "background-color": "#000",
      opacity: 0.3,
      width: "28px",
      height: "28px",
      text: t("payPage.loading"),
      "lock-scroll": true,
    });

    let product = computed(() => {
      return products.filter((d) => {
        return d.id === proId;
      })[0];
    });

    let CardFormName = ref();
    let cardFormVisible = ref(false);
    let clientSecret = ref("");
    const cardFromReady = ref(false);

    const { stripe } = useStripe({ key: config.stripe.key });

    let order = null;

    function createOrder(force = false) {
      if (order && !force) {
        return Promise.resolve(order);
      }
      return axios
        .post(`${config.domains.pop}/api/accounts/me/orders`, {
          product: product.value.id,
        })
        .then((res) => {
          order = res.data;
          return Promise.resolve(order);
        })
        .catch((err) => {
          console.log(err);
          order = null;
          return Promise.resolve(null);
        });
    }

    async function cardpay(event) {
      const { stripe, elements } = event;
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: location.href.replace(
            location.hash,
            `#payResult${search}`
          ),
        },
      });

      if (result.error) {
        console.log(`Pay failed: ${result.error.message}`);
        await router.push({
          name: "payResult",
          params: { result: "error" },
          query: { proId: route.query.proId },
        });
      } else {
        console.log("Pay successed.");
      }
    }

    async function grabpay() {
      const loader = $loading.show();
      const order = await createOrder(true);
      if (!order) {
        loader.hide();
        return;
      }

      const clientSecretRes = await axios.post(
        `${config.domains.pop}/api/accounts/me/orders/${order.id}/payments/stripe`,
        {
          pay_platform: "web",
          pay_method: "grabpay",
        }
      );

      if (clientSecretRes.status !== 200) {
        loader.hide();
        console.log("Pay failed.");
        await router.push({
          name: "payResult",
          params: { result: "error" },
          query: { proId: route.query.proId },
        });
        return;
      }
      stripe.value
        .confirmGrabPayPayment(
          clientSecretRes.data.url,
          {
            return_url:
              config.stripe.grabpay.returnUrl ||
              location.href.replace(location.hash, `#payResult${search}`),
          },
          {
            handleActions: false,
          }
        )
        .then((res) => {
          loader.hide();
          location.href = res.paymentIntent.next_action.redirect_to_url.url;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function alipay() {
      const loader = $loading.show();
      const order = await createOrder();
      if (!order) {
        loader.hide();
        return;
      }

      axios
        .post(
          `${config.domains.pop}/api/accounts/me/orders/${order.id}/payments/auto`,
          {
            pay_platform: "web",
            return_url:
              config.stripe.alipay.returnUrl ||
              location.href.replace(location.hash, `#payResult${search}`),
          }
        )
        .then((res) => {
          loader.hide();
          location.href = res.data.url;
        })
        .catch((err) => {
          loader.hide();
          console.log(`Pay order failed: ${err}.`);
          router.push({
            name: "payResult",
            params: { result: "error" },
            query: { proId: route.query.proId },
          });
        });
    }

    async function showCardForm() {
      const loader = $loading.show();
      const order = await createOrder(true);
      if (!order) {
        loader.hide();
        return;
      }

      const clientSecretRes = await axios.post(
        `${config.domains.pop}/api/accounts/me/orders/${order.id}/payments/stripe`,
        {
          pay_platform: "web",
          pay_method: "card",
        }
      );

      if (clientSecretRes.status !== 200) {
        console.log("Pay failed.");
        loader.hide();
        await router.push({
          name: "payResult",
          params: { result: "error" },
          query: { proId: route.query.proId },
        });
        return;
      }

      clientSecret.value = clientSecretRes.data.url;
      CardFormName.value = "card-form";
      cardFormVisible.value = true;
      loader.hide();
    }

    function closeModal() {
      cardFormVisible.value = false;
    }

    function readyHanlder() {
      cardFromReady.value = true;
    }

    function freshMe() {
      store.dispatch("account/fresh");
    }

    onMounted(() => {
      // TODO: callback page
      // const url = new URL(window.location.href);
      // const paymentIntentClientSecret = url.searchParams.get(
      //   'payment_intent_client_secret'
      // );
      // if (paymentIntentClientSecret) {
      //   stripe.value.retrievePaymentIntent(paymentIntentClientSecret).then(function(result) {
      //     const paymentIntent = result.paymentIntent;
      //     console.log(paymentIntent.status);
      //   });
      // }
    });

    return {
      locale,
      product,
      cardFormVisible,
      cardFromReady,
      clientSecret,
      CardFormName,
      grabpayTabLink,
      alipayTabLink,
      showCardForm,
      cardpay,
      alipay,
      grabpay,
      closeModal,
      readyHanlder,
      freshMe,
    };
  },
  created() {},
};
</script>

<style lang="less" scoped>
.pt-40 {
  padding-top: 40px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-28 {
  margin-top: 28px;
}

p {
  margin: 0;
}

.main {
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
}

.product {
  width: 360px;
  padding-bottom: 6px;
  border-bottom: 1px solid #e5e5e5;

  .flex {
    align-items: flex-start;
  }

  .l {
    flex: 1;
    text-align: left;
  }

  .r {
    text-align: right;
    white-space: nowrap;
  }

  .bold {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.26;
    white-space: nowrap;
  }

  .sm {
    color: #b9b9b9;
    font-size: 14px;
    line-height: 1.26;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
  }
}

.pay-type {
  width: 360px;
  height: 66px;
  background: #eef8ff;
  border: 1px solid #ddf3ff;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 16px 0 20px;

  &:hover {
    background: #cbe8fd;
    border-color: #b6e0ff;
  }

  &:active {
    background: #adddff;
    border-color: #89cdff;
  }

  img {
    display: block;
  }

  .icon-card {
    width: 169px;
  }

  .icon-ali {
    width: 32px;
  }

  .icon-grab {
    width: 74px;
  }

  .sm {
    font-size: 12px;
  }

  .desc {
    color: #b9b9b9;
  }

  .bold {
    font-size: 14px;
    font-weight: 700;
  }

  .r {
    text-align: right;
    flex: 1;
  }
}

.paid-notice {
  font-size: 14px;
  line-height: 18px;
  a {
    margin-left: 4px;
    text-decoration: #00a3ff;
    color: #00a3ff;
    text-decoration-line: underline;
  }
}

.modal {
  z-index: 10001;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    border-radius: 8px;
    padding: 32px 30px;
    box-sizing: border-box;
    width: 420px;
    background: #fff;
  }
}
.modal.ready {
  top: 0;
  height: auto;
}

@media all and (max-width: 475px) and (min-width: 320px) {
  .modal {
    .modal-content {
      width: calc(100% - 48px);
      padding: 24px;
    }
  }

  .pay-type {
    width: calc(100% - 48px);
    height: 76px;
  }
  .product {
    padding-bottom: 14px;
    width: calc(100% - 48px);
    box-sizing: border-box;
  }
  .product {
    .flex {
      margin-top: 24px;
    }
  }
  .product .sm {
    margin-top: 8px;
  }
  .pay-type {
    .r {
      .desc {
        margin-left: 46px;
      }
    }
  }
  .pay-type {
    .sm {
      font-size: 14px;
      margin-top: 2px;
    }
  }
}
</style>
