<template>
  <div class="name">
    {{ name }}
  </div>
  <div class="info">
    <div v-for="rule in rules" :key="rule">✓ {{ rule }}</div>
  </div>
  <div class="but flex" v-if="!store.isLogin && id === 'pro_0'">
    <button @click="next(id)">
      {{ $t(`productPage.${id}.butText`) }}
    </button>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ProductText",
  components: {},
  emits: ["go"],
  props: {
    id: {
      type: String,
      require: true,
    },
    name: {
      type: String,
      require: true,
    },
    rules: {
      type: Array,
      require: true,
    },
  },
  setup(props, context) {
    let route = useRoute();
    let store = useStore().state;

    function next(id) {
      context.emit("go", id);
    }

    return { store, route, next };
  },
  created() {},
};
</script>

<style scoped>
.name {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 12px;
}
.info {
  font-size: 16px;
  line-height: 26px;
}
.but {
  width: 100%;
  margin-top: 24px;
}
.but button {
  height: 44px;
  font-size: 16px;
  width: 80%;
  min-width: 200px;
  max-width: 327px;
}
</style>
