<template>
  <div class="flex main">
    <div>
      <div style="height: 40px"></div>
      <div class="title">{{ $t("about.name") }}</div>
      <div class="banner about1"></div>
      <div class="banner about2"></div>
      <div class="banner about3"></div>
      <div style="height: 28px"></div>
      <div class="content">{{ $t("about.content") }}</div>
    </div>
  </div>
  <div style="height: 50px"></div>
  <ColumnToolsAndSiteMap :showTool="false"></ColumnToolsAndSiteMap>
</template>

<script>
import ColumnToolsAndSiteMap from "../components/ColumnToolsAndSiteMap";
export default {
  name: "About",
  components: { ColumnToolsAndSiteMap },

  setup() {
    return {};
  },
  created() {},
};
</script>

<style scoped>
.main {
  width: 703px;
}
.title {
  font-weight: 700;
  font-size: 22px;

  text-align: center;
}
.content {
  font-weight: 400;
  font-size: 14px;
}
.banner > div {
  min-width: 253px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 60px;
  color: #ffffff;
  margin-top: 50px;
  user-select: none;
}
.banner {
  border-radius: 2px;
}
.about1 {
  margin-top: 40px;
  background-image: url("~@/assets/images/about/about1.jpeg");
  width: 703px;
  height: 396px;
  display: flex;
  justify-content: center;
  background-size: 100%;
}
.about2 {
  margin-top: 40px;
  background-image: url("~@/assets/images/about/about2.jpeg");
  width: 703px;
  height: 396px;
  display: flex;
  justify-content: center;
  background-size: 100%;
}
.about3 {
  margin-top: 40px;
  background-image: url("~@/assets/images/about/about3.jpg");
  width: 703px;
  height: 396px;
  display: flex;
  justify-content: center;
  background-size: 100%;
}
</style>
