import { createStore } from "vuex";
import { files } from "@/common/fileRule";
import { tools } from "@/common/tools";
import { AccountStoreModule } from "./account";
import utils from "../util/utils";

// Create a new store instance.
const store = createStore({
  modules: {
    account: AccountStoreModule,
  },
  state() {
    return {
      files: files,
      tools,
      themeColor: "#ffffff",
      isLogin: false,
      currentEmail: "",
      currentPassword: "",
      countTime: 59,
      timeInterval: null,
      canResendCode: false,
      recents: [],
    };
  },
  mutations: {
    reset(state) {
      state.isLogin = false;
      state.workStatus = "chooseFile";
      // state.work = "ptw";
      state.currentEmail = "";
      state.countTime = 59;
      window.clearInterval(state.timeInterval);
      state.timeInterval = null;
      state.currentRule = files.pdf;
    },
    resetTimeInterval(state) {
      state.countTime = 59;
      window.clearInterval(state.timeInterval);
      state.timeInterval = null;
    },
    changeLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    changeRecents(state, recent) {
      if (!recent) return;
      const _index = state.recents.indexOf(recent);
      if (_index >= 0) {
        state.recents.splice(_index, 1);
      }
      if (state.recents.length > 2) {
        state.recents.pop();
      }
      state.recents.unshift(recent);
      utils.setRecents(state.recents, state.account.code);
    },
    change(state, data) {
      state[data["key"]] = data["value"];
    },
    setCurrentEmail(state, currentEmail) {
      state.currentEmail = currentEmail;
    },
  },
});
export default store;
