<template>
  <div class="product" :style="{ backgroundColor: backgroundColor }">
    <div
      class="name"
      :style="{ color: textColor, borderBottom: '1px solid' + divideLineColor }"
    >
      {{ name }}
    </div>
    <div class="price-parent">
      <span class="price">{{ $t("productPage.unit") }}{{ price }}</span>
      <span class="period"
        >&nbsp;/&nbsp;{{ $t("productPage.showPeriod") }}</span
      >
    </div>
    <div
      class="billed"
      :style="{ color: billedColor }"
      v-html="targetBilled"
    ></div>
    <div class="info">
      <div v-for="rule in rules" :key="rule">✓ {{ rule }}</div>
    </div>
    <div
      v-if="id !== 'pro_0' || !store.isLogin"
      :style="{ borderTop: '1px solid' + divideLineColor }"
    ></div>
    <div class="button-parent">
      <button @click="next(id)" v-if="!store.isLogin || id !== 'pro_0'">
        {{ butText }}
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { i18nReplace } from "@/language/i18n";
import utils from "@/util/utils";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "Product",
  components: {},
  props: {
    id: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    divideLineColor: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      default: "**",
    },
    period: {
      type: String,
      default: "month",
    },
    billed: {
      type: String,
      default: "",
    },
    billedColor: {
      type: String,
      default: "",
    },
    butText: {
      type: String,
      default: "Buy Pro",
    },
    totalPrice: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props, context) {
    let store = useStore().state;
    let { t } = useI18n();
    let targetBilled = computed(() => {
      return props.totalPrice
        ? i18nReplace(props.billed, [
            {
              totalPrice: `${t("productPage.unit")}${utils.calculateDecimal(
                props.totalPrice
              )}`,
            },
          ])
        : props.billed;
    });

    function next(id) {
      context.emit("go", id);
    }
    return {
      targetBilled,
      store,
      next,
      i18nReplace,
      utils,
    };
  },
  created() {},
};
</script>

<style scoped>
.product {
  min-width: 242px;
  width: 100%;
  background: #f8fcff;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 20px 20px 24px 20px;
  height: 410px;
  font-size: 14px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 25px 42px auto 1fr 24px auto;
  align-items: flex-start;
  justify-content: flex-start;
}
.product > div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.name {
  font-weight: 700;
  font-size: 16px;

  padding-bottom: 6px;
}
.price-parent {
  margin-top: 14px;
}
.price {
  font-weight: bold;
  font-size: 24px;
}
.info {
  flex-direction: column;
}
.info > div {
  width: 100%;
  height: 28px;
}
.product .billed {
  height: 56px;
  padding: 14px 0 0 0;
  align-items: flex-start;
}
.product .button-parent {
  justify-content: center;
  height: 36px;
}
.period {
  margin-bottom: 3px;
}
</style>
