<template>
  <div class="tool-box" @click="toWork(work)">
    <img :src="icon" alt="load error" />
    <span>{{ name }}</span>
  </div>
</template>

<script>
// import { toRefs, reactive, ref } from 'vue'
import utils from "@/util/utils";
import { useStore } from "vuex";

export default {
  name: "ToolBox",
  components: {},
  props: {
    icon: String,
    name: String,
    work: String,
  },
  setup() {
    let useStores = useStore();
    let store = useStores.state;

    function toWork(work) {
      try {
        utils.changeRecents(store.account.code, work);
        let _local = store.tools[work].local;
        utils.toWork(_local);
      } catch (e) {
        console.log(e);
      }
    }
    return {
      toWork,
    };
  },
  created() {},
};
</script>

<style scoped>
.tool-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 148px;
  min-height: 58px;
  padding: 6px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;

  text-align: center;
  font-weight: bold;
  white-space: nowrap;
}
.tool-box img {
  width: 46px;
  height: 46px;
}
</style>
