import "@stripe/stripe-js";

import App from "./App.vue";
import { createApp } from "vue";
import router from "./router/index";
import VueAxios from "vue-axios";
import axios from "axios";
import store from "./store/stroe";
import { i18n } from "./language/i18n";

import utils from "@/util/utils";
import products from "@/store/product";

const RATE = 0.2123;
utils.getProducts().then(res => {
  let locals = Object.keys(products.find(p => p.id !== "pro_0").price);
  res.data.forEach(d => {
    products.forEach(p => {
      if (p.id === d.id) {
        locals.forEach(l => {
          const _totalPrice = utils.calculateDecimal((d.price / 100) * RATE)
          p.totalPrice[l] = utils.complementDecimal(_totalPrice);
          let _price = utils.calculateDecimal( _totalPrice / p.monthConversion);
          p.price[l] = utils.complementDecimal(_price);
        })
      }
    })
  })
})

let app = createApp(App);
app.config.globalProperties.$buttonStateDuration = 1000;
app.config.globalProperties.$languages = {
  en: "English",
  zh: "简体中文",
  zh_tc: "繁体中文",
};
app.use(VueAxios, axios).use(router).use(i18n).use(store).mount("#app");
