<template>
  <Product
    :id="free.id"
    :name="free.name"
    :price="free.price"
    :billed="free.billed"
    :butText="store.isLogin ? '' : $t('signUpPage.name')"
    :rules="rules"
    :textColor="free.textColor"
    :backgroundColor="free.backgroundColor"
    :divideLineColor="free.divideLineColor"
    @go="toSignUp"
  />
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Product from "./Product";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import products from "../store/product";

export default {
  name: "FreeProduct",
  components: { Product },
  setup() {
    let store = useStore().state;
    let router = useRouter();
    const { t } = useI18n();
    let rules = computed(() => {
      return t(`productPage.pro_0.rules`).split(",");
    });
    function toSignUp() {
      router.push({ name: "signUp", query: { from: "product" } });
    }

    let free = computed(() => {
      return products.find((p) => p.id === "pro_0");
    });

    return {
      free,
      toSignUp,
      store,
      rules,
    };
  },
  created() {},
};
</script>

<style scoped></style>
