<template>
  <div
    class="mask"
    :style="{ display: showMark ? 'block' : 'none' }"
    @click="cancel"
  ></div>
  <div class="reset-body flex">
    <img
      class="icon-cancel"
      @click="cancel"
      src="@/assets/images/icon_cancel.svg"
      alt="load error"
    />
    <div class="reset flex">
      <div class="">
        <div class="title">
          {{ $t("changePasswordPage.title") || "Change password" }}
        </div>
        <IPassword
          :has-parent-hint="hasHintPasswordCurrent"
          :placeholder="$t('changePasswordPage.currentPasswordPlaceholder')"
          :hint="$t('changePasswordPage.authPasswordErr')"
          @oninput="oninputPasswordFirst"
          ref="passwordCurrentRef"
        ></IPassword>
        <div class="split-line"></div>
        <IPassword
          :icon="require('@/assets/images/account/icon-shield.png')"
          @updateValue="updateValuePasswordFirst"
          :has-parent-hint="hasHintPasswordFirst"
          :show-grid="true"
          :placeholder="$t('changePasswordPage.newPasswordPlaceholder')"
          :hint="firstHint"
          ref="passwordFirstRef"
        ></IPassword>
        <IPassword
          class="last-input"
          :icon="require('@/assets/images/account/icon-shield.png')"
          @updateValue="updateValuePasswordLast"
          :has-parent-hint="hasHintPasswordLast"
          @oninput="oninputPasswordLast"
          @inputBlur="onBlurPasswordLast"
          :placeholder="$t('changePasswordPage.confirmPasswordPlaceholder')"
          ref="passLast"
          :confirm="true"
          :hint="confirmHint"
          @keypress.enter="updatePassword"
        ></IPassword>
        <Button @click="updatePassword" :status="butStatus" class="button">
          <span v-if="butStatus !== 'ok'">
            {{ $t("changePasswordPage.buttonText") || "Confirm" }}
          </span>
          <img
            v-if="butStatus === 'ok'"
            src="@/assets/images/account/icon_check_bgw.png"
            alt="load error"
          />
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import IPassword from "../components/IPassword";
import Button from "../components/Button";
import utils from "../util/utils";
import { useStore } from "vuex";
import Account from "../store/account";
import { useI18n } from "vue-i18n";
export default {
  name: "changePass",
  components: {
    IPassword,
    Button,
  },
  setup() {
    const { t } = useI18n();
    let useStores = useStore();
    let store = useStores.state;

    let router = useRouter();
    let butStatus = ref("before");
    let buttonStateDuration = ref(1000);

    let showMark = ref(true);
    function cancel() {
      if (!showMark.value) return;
      showMark.value = false;
      router.back();
    }

    let passwordFirstRef = ref(null);
    let passwordCurrentRef = ref(null);
    let passLast = ref(null);
    let passwordFirst = ref("");
    let passwordLast = ref("");
    let confirmHint = ref(t("error.comparePasswordErr"));
    let firstHint = ref("");
    let changeStatusOK = ref(false);

    let hasHintPasswordLast = ref(false);
    let hasHintPasswordFirst = ref(false);
    let hasHintPasswordCurrent = ref(false);

    function updateValuePasswordFirst(e) {
      hasHintPasswordFirst.value = false;
      passwordFirst.value = e;
    }
    function updateValuePasswordLast(e) {
      hasHintPasswordLast.value =
        !!passwordFirst.value && e !== passwordFirst.value;
      passwordLast.value = e;
    }

    function oninputPasswordFirst(e) {
      hasHintPasswordFirst.value = hasHintPasswordLast.value =
        !!passwordFirst.value &&
        e !== passwordFirst.value.substring(0, e.length);
    }
    function oninputPasswordLast(e) {
      passwordLast.value = e;
      hasHintPasswordLast.value = false;
    }

    function onBlurPasswordLast(e) {
      hasHintPasswordLast.value =
        !!passwordFirst.value &&
        e !== passwordFirst.value;
    }

    function setFirstStatus() {
      hasHintPasswordFirst.value = true;
      firstHint.value = passLast.value.defHint;
    }
    function resetCurrentStatus() {
      hasHintPasswordCurrent.value = false;
    }
    function updatePassword() {
      if (!passwordFirst.value) {
        setFirstStatus();
        return;
      } else if (
        !passwordLast.value ||
        passwordLast.value !== passwordFirst.value
      ) {
        hasHintPasswordLast.value = true;
        return;
      }
      if (passLast.value.lv < 5) {
        hasHintPasswordLast.value = true;
        setFirstStatus();
        return;
      }
      hasHintPasswordFirst.value = false;
      hasHintPasswordLast.value = false;
      resetCurrentStatus();
      butStatus.value = "going";

      utils
        .login(store.account.email, passwordCurrentRef.value.ivalue)
        .then(() => {
          utils
            .updatePassword(passwordLast.value)
            .then((res) => {
              butStatus.value = "ok";
              utils.setCookie("sid", res.data.sid);
              Account.storage(res.data.sid);
              useStores.dispatch("account/init");
              useStores.commit("changeLogin", true);
              changeStatusOK.value = true;
              setTimeout(function () {
                cancel();
              }, buttonStateDuration.value);
            })
            .catch((e) => {
              console.log(e);
              cancel();
            });
        })
        .catch(() => {
          console.log("The password is incorrect!");
          hasHintPasswordCurrent.value = true;
          butStatus.value = "before";
        });
    }

    onUnmounted(() => {
      document.body.style.overflow = "auto";
    });

    return {
      buttonStateDuration,
      showMark,
      cancel,
      passwordCurrentRef,
      passwordFirstRef,
      firstHint,
      confirmHint,
      passLast,
      butStatus,
      hasHintPasswordFirst,
      hasHintPasswordLast,
      hasHintPasswordCurrent,
      passwordFirst,
      passwordLast,
      updateValuePasswordLast,
      updateValuePasswordFirst,
      oninputPasswordLast,
      onBlurPasswordLast,
      oninputPasswordFirst,
      updatePassword,
    };
  },
  created() {
    document.body.style.overflow = "hidden";
    this.buttonStateDuration = this.$buttonStateDuration;
  },
};
</script>

<style scoped>
.reset-body {
  margin: auto;
  flex-direction: column;
  z-index: 9;
}
.reset {
  background: #ffffff;
  border-radius: 8px;
  width: 420px;
  text-align: center;
  padding: 32px 0;
  font-size: 14px;
}
.reset > div {
  width: 360px;
}
.reset .title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 28px;
}
.reset .flex {
  flex-direction: column;
}
.button {
  margin-top: 24px;
}
.button img {
  width: 28px;
  height: 28px;
}

.last-input {
  margin-top: 24px;
}
.split-line {
  height: 24px;
  border-bottom: 1px solid #e7e7e7;
  border-radius: 1px;
  box-sizing: border-box;
  margin-bottom: 24px;
}
.icon-cancel {
  display: none;
  position: absolute;
  top: 12px;
  right: 18px;
  cursor: pointer;
}

@media all and (max-width: 570px) and (min-width: 320px) {
  .reset-body {
    position: relative;
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin: auto 20px;
  }
  .icon-cancel {
    display: block;
  }

  .reset {
    padding: 28px 18px ;
    box-sizing: border-box;
  }
  .reset .title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .reset,.reset > div {
    width: 100%;
  }
  .last-input {
    margin-top: 28px;
  }

  .button {
    font-size: 16px;
    margin-top: 14px;
  }

}

</style>
